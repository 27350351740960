import React from 'react';
import {Table, TBody, TR, TD, A} from 'oy-vey';
import EmptySpace from './EmptySpace';

const Footer = (props) => {
  const style = {
    backgroundColor: '#f7f7f7',
  };

  const spaceStyle = {
    paddingBottom: '25px',
    paddingLeft: '5px',
    color: '#9ca299',
    fontSize: '14px',
    textAlign: 'center',
  };

  return (
    <Table width="100%" style={style}>
      <TBody>
        <TR>
          <TD>
            <div style={spaceStyle}>Enviado con &#10084; por {props.siteName}</div>
            <div style={spaceStyle}>Powered by &#9889; <a href='https://www.linkedin.com/company/legendary-code/?viewAsMember=true' target='_blank'>Legendary Code</a></div>
          </TD>
        </TR>
      </TBody>
    </Table>
  );
};

export default Footer;
