
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';
import s from './Success.css';
import { url } from '../../config';

// Función auxiliar para obtener los parámetros de la URL



 class Success extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    hosteddataid: PropTypes.string.isRequired,
    bname: PropTypes.string.isRequired,
    cardnumber: PropTypes.string.isRequired,
  }



  render() {

    const {title} = this.props;
    const {hosteddataid, bname, cardnumber} = this.props;
    return (
      <div className={s.container}>
        { hosteddataid != '' && bname != '' && cardnumber != '' ? (
          <div>
            <h1 className={s.containerTitle}>{bname}, tu pago fue procesado con éxito.</h1>
            <h2 className={s.containerSubTitle}>El pago con tu tarjeta {cardnumber} fue aprobado y hemos enviado detalles de su facturación a su casilla de correo
            electrónico.</h2>
            <p className={s.containerParagrph}>Ya podes regresar a tu panel de control.</p>
            <div className={s.buttonContainer}>
              <a className={s.backBtn} href={"/dashboard"} >
                Ir al panel de control
              </a>
              </div>
          </div>
        ):(
          <div>
            <h1 
            className={s.containerTitle}>
              No se pudo procesar tu pago.
              </h1>
            <h2 
            className={s.containerSubTitle}>
              ¿Te perdiste?
              </h2>
              <div className={s.buttonContainer}>
              <a className={s.backBtn} href={"/"} >
                Volver a la página de inicio
              </a>
              </div>
          </div>
        )}
      </div>
    )
  }
}



export default compose(
    withStyles(s)(Success)
  );