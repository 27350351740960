import axios from 'axios';
// import { getAuthToken } from './auth';
import { backendUrl } from '../config';

export const api = axios.create({
  baseURL: backendUrl,
});

// api.interceptors.request.use((config) => {
//   if (token) {
//     config.headers.authToken = token;
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

// Interceptor de respuesta
// api.interceptors.response.use((response) => {
//   console.log('Response:', response);
//   return response;
// }, (error) => {
//   console.error('Response Error:', error);
//   return Promise.reject(error);
// });


// const makeGraphQLRequest = async (query, variables) => {
//     try {
//       const response = await api.post(
//         //'/graphql',
//         {
//           query,
//           variables
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         }
//       );
  
//       if (response.status !== 200) {
//         throw new Error(response.data.errors[0].message);
//       }
//       console.log("Response: " + JSON.stringify(response.data.data));
//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         // The request was made and the server responded with a status code
//         // that falls out of the range of 2xx
//         console.error('GraphQL error:', error.response.data);
//         throw new Error(error.response.data.message || 'An error occurred with the GraphQL request');
//       } else if (error.request) {
//         // The request was made but no response was received
//         console.error('No response received:', error.request);
//         throw new Error('No response received from the server');
//       } else {
//         // Something happened in setting up the request that triggered an Error
//         console.error('Error setting up request:', error.message);
//         throw error;
//       }
//     }
//   };

export default api;