import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Es.css';
import tickImage from '../../../../public/SiteIcons/tick.png'; // Ajusta la ruta según tu proyecto

class ListExtras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccionados: new Set(),
      total: 0
    };
  }

  handleChange = (id, price) => {
    this.setState((prevState) => {
      const seleccionados = new Set(prevState.seleccionados);
      let newTotal = prevState.total;

      if (seleccionados.has(id)) {
        seleccionados.delete(id);
        newTotal -= price;
      } else {
        seleccionados.add(id);
        newTotal += price;
      }

      // Crea un array con los ítems seleccionados
      const itemsSeleccionados = Array.from(seleccionados).map(id => {
        return this.props.listado.find(item => item.id === id);
      });

      // Llama a la función de callback pasada por el padre con el total y los ítems seleccionados por separado
      if (this.props.onTotalChange) {
        this.props.onTotalChange(newTotal, itemsSeleccionados);
      }

      return {
        seleccionados,
        total: newTotal
      };
    });
  };

  render() {
    const { listado } = this.props;
    const { seleccionados, total } = this.state;

    return (
      <div className={s.container}>
        <h3 className={s.titulo}>Lista de Adicionales</h3>
        <p className={s.parrafo1}>Selecciona adicionales para tu reserva</p>
        <ul>
          {listado.map((item) => (
            <li key={item.id} className={s.listItem}>
              <label className={s.itemName}>
                <input
                  type="checkbox"
                  id={`checkbox-${item.id}`}
                  checked={seleccionados.has(item.id)}
                  onChange={() => this.handleChange(item.id, item.price)}
                  className={s.checkbox}
                />
                <span className={s.checkboxCustom}></span>
                {item.name} - ${item.price}
              </label>
            </li>
          ))}
        </ul>
        <hr />
        <h4 className={s.total}>Total: ${total}</h4>
      </div>
    );
  }
}

export default withStyles(s)(ListExtras);
