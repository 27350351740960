import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Accordion.css';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  toggleAccordion() {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  render() {
    const { title, content } = this.props;
    const { isActive } = this.state;

    return (
      <div className={`${s.accordionItem} ${s.accordion}`}>
        <div
          className={s.accordionHeader}
          onClick={() => this.toggleAccordion()}
        >
          <span>{title}</span>
          <span className={`${s.accordionIcon} ${isActive ? s.rotate : ''}`}>
            &#9660;
          </span>
        </div>
        {isActive && (
          <div className={s.accordionBody}>
            {content}
          </div>
        )}
      </div>
    );
  }
}


export default withStyles(s)(Accordion);
