import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import SidePanel from './SidePanel';
import FooterButton from './FooterButton';
import CommonFormComponent from '../CommonField/CommonFormComponent';

import update from './update';
import messages from '../../locale/messages';
import validate from './validate';

import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';

class Page2 extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      carType: [],
      year: [],
      make: [],
      otherVehicleMake:[],
      odometer: [],
      isModelValue: [],
      selectedCarType: '',
      otherVehicleMake: '',
      otherVehicleModel: '',
      carTypeShow: ["Automovil", "Camioneta", "Camion", "Moto", "Coupe", "Convertible", "Hatchback", "Sedan", "Van", "SUV", "Pickup", "Minivan", "Wagon", "Crossover"]
    }
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    
    if (listingFields != undefined) {
      this.setState({
        carType: listingFields.carType,
        otherVehicleMake: listingFields.otherVehicleMake,
        year: listingFields.year,
        make: listingFields.make,
        odometer: listingFields.odometer,
      });
    }
  }

  componentDidMount() {
  const { valid, listingFields, makeName, change } = this.props; // Asegúrate de tener acceso a 'change'
  let ModelValues = listingFields && listingFields.model && listingFields.model.filter(o => o.makeType == makeName);
  
  // Aquí verificamos si el formulario es válido
  if (valid) {
    this.setState({ isDisabled: false });
  } else {
    this.setState({ isDisabled: true });
  }
  
  if (listingFields != undefined) {
    const selectedCarType = this.state.carType.find(item => item.isEnable == 1)?.itemName; // Tipo de auto seleccionado por defecto
    
    this.setState({
      isModelValue: ModelValues,
      selectedCarType,  // Setear el tipo de auto seleccionado por defecto
    });

    if (listingFields != undefined) {
      // Preseleccionar el primer valor de odometer
      const defaultOdometer = listingFields.odometer?.find(item => item.isEnable == 1)?.id;
      if (defaultOdometer) {
        change('odometer', defaultOdometer);
      }
  
      // Preseleccionar el primer valor de year
      const defaultYear = listingFields.year?.find(item => item.isEnable == 1)?.id;
      if (defaultYear) {
        change('year', defaultYear);
      }
  
      // Preseleccionar el primer valor de transmission (1: Automático, 2: Manual)
      change('transmission', 1);  // Si deseas Automático como predeterminado
    }

    // Aplicar la lógica de carTypeShow y setear el valor de typeId al montar el componente
    const defaultTypeId = this.state.carTypeShow.includes(selectedCarType) ? 20 : 22;
    
    // Seteamos el valor de typeId en el form
    change('typeId', defaultTypeId);
  }
}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields, makeName } = nextProps;
    let ModelValues = listingFields && listingFields.model && listingFields.model.filter(o => o.makeType == makeName);
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
    if (listingFields != undefined) {
      this.setState({
        carType: listingFields.carType,
        year: listingFields.year,
        make: listingFields.make,
        odometer: listingFields.odometer,
        isModelValue: ModelValues,
      });
    }
  }

  changeModelType = async (e) => {
    const { change, listingFields } = this.props;
    let newValue = e.target.value;
    let ModelValue = listingFields.model && listingFields.model.filter(o => o.makeType == newValue);
    this.setState({
      isModelValue: ModelValue,
      makeValue: newValue,
      isTrimValue: []
    })
    if (ModelValue && ModelValue.length > 0 && ModelValue[0].isEnable == "1") {
      change('model', ModelValue && ModelValue[0].id)
    } else {
      change('model', "")
    }
  }

  handleCarTypeChange = (event) => {
    const { change } = this.props; 
    const selectedId = event.target.value; // ID seleccionado
    const selectedCarType = this.state.carType.find(item => item.id == selectedId)?.itemName; // Buscas el itemName correspondiente al ID
  
    this.setState({ selectedCarType });
  
    const newTypeId = this.state.carTypeShow.includes(selectedCarType) ? 20 : 22;
  
  change('typeId', newTypeId);
  }
  




  render() {
    const { handleSubmit, previousPage, nextPage, existingList, formPage, step, modelValue } = this.props;
    const { isDisabled, carType, make, isModelValue, year, odometer, selectedCarType, carTypeShow, otherVehicleMake } = this.state;
    const { formatMessage } = this.props.intl;

    let path = "index";
    if (existingList) {
      path = "home";
    }
    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step1HeadingNew)}
          landingContent={formatMessage(messages.whatKindOfPlaceListing)}
        />
        <div>
          <form onSubmit={handleSubmit}>
            <div className={s.landingMainContent}>
              <FormGroup className={s.formGroup}>
                <ControlLabel className={s.landingLabel}>
                  <FormattedMessage {...messages.whatGuestHave} />
                </ControlLabel>
                <Field
                  name="carType"
                  component={CommonFormComponent}
                  inputClass={cx(s.formControlSelect, s.jumboSelect, s.marginBottompageTwo)}
                  onChange={this.handleCarTypeChange}

                >
                  {
                    carType.map((value, key) => {
                      return (
                        value.isEnable == 1 && <option
                          value={value.id}
                          key={key}
                        >
                          {value.itemName}
                        </option>
                      )
                    })
                  }
                </Field>
              </FormGroup>

              
                <FormGroup className={s.formGroup}>
                  <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.whatTypeOfProperty} />
                  </ControlLabel>
                  <Field
                    name="make"
                    component={CommonFormComponent}
                    inputClass={cx(s.formControlSelect, s.jumboSelect, s.marginBottompageTwo)}
                    onChange={this.changeModelType}
                  >
                    <option value="">
                      {formatMessage(messages.selectLabel)}
                    </option>
                    {carTypeShow.includes(selectedCarType) ? 
                      make.map((value, key) => {
                        return (
                          value.isEnable == 1 && <option
                            value={value.id}
                            key={key}
                          >
                            {value.itemName}
                          </option>
                        )
                      }) : otherVehicleMake.map((value, key) => {
                        return (
                          value.isEnable == 1 && <option
                            value={value.id}
                            key={key}
                          >
                            {value.itemName}
                          </option>
                        )
                      }
                      )
                    }
                  </Field>
                </FormGroup>
              

              
                <FormGroup className={s.formGroup}>
                  <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.modelLabel} />
                  </ControlLabel>
                  <Field
                    name="model"
                    component={CommonFormComponent}
                    inputClass={cx(s.formControlSelect, s.jumboSelect, s.marginBottompageTwo)}
                  >
                    <option value="">
                      {formatMessage(messages.selectLabel)}
                    </option>
                    {
                      isModelValue?.map((value, key) => {
                        return (
                          value?.isEnable == 1 && <option
                            value={value?.id}
                            key={key}
                          >
                            {value?.itemName}
                          </option>
                        )
                      })
                    }
                  </Field>
                </FormGroup>
            

              <div className={carTypeShow.includes(selectedCarType) ? s.visible : s.hidden}>

                <FormGroup className={s.formGroup}>
                  <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.year} />
                  </ControlLabel>
                  <Field
                    name="year"
                    component={CommonFormComponent}
                    inputClass={cx(s.formControlSelect, s.jumboSelect, s.marginBottompageTwo)}
                  >
                    {
                      year.map((value, key) => {
                        return (
                          value?.isEnable == 1 && <option value={value?.id} key={key}>{value?.itemName}</option>
                        )
                      })
                    }
                  </Field>
                </FormGroup>
              </div>

              <div className={carTypeShow.includes(selectedCarType) ? s.visible : s.hidden}>

                <FormGroup className={s.formGroup}>
                  <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.isPersonalHome} />
                  </ControlLabel>
                  <Field
                    name="transmission"
                    component={CommonFormComponent}
                    inputClass={cx(s.formControlSelect, s.jumboSelect, s.marginBottompageTwo)}
                  >
                    <option value="1">
                      {formatMessage(messages.Automatic)}
                    </option>
                    <option value="2">
                      {formatMessage(messages.Manuall)}
                    </option>
                  </Field>
                </FormGroup>
              </div>

              <div className={carTypeShow.includes(selectedCarType) ? s.visible : s.hidden}>
                <FormGroup className={s.formGroup}>
                  <ControlLabel className={s.landingLabel}>
                    <FormattedMessage {...messages.odometer} />
                  </ControlLabel>
                  <Field
                    name="odometer"
                    component={CommonFormComponent}
                    inputClass={cx(s.formControlSelect, s.jumboSelect, s.marginBottompageTwo)}
                  >
                    {
                      odometer?.map((value, key) => {
                        return (
                          value.isEnable == 1 && <option value={value?.id} key={key}>
                            {value?.itemName}
                          </option>
                        )
                      })
                    }
                  </Field>
                </FormGroup>
              </div>


              <div className={s.hidden}>

                <FormGroup className={s.formGroup}>
                  <ControlLabel className={s.landingLabel}>
                    typeId
                  </ControlLabel>
                  <Field
                    name="typeId"
                    component={CommonFormComponent}
                    type='input'
                    inputClass={cx(s.formControlSelect, s.jumboSelect, s.marginBottompageTwo)}
                  >
                    
                  </Field>
                </FormGroup>
              </div>



            </div>
            <FooterButton
              isDisabled={isDisabled}
              nextPage={nextPage}
              previousPage={previousPage}
              nextPagePath={"location"}
              previousPagePath={path}
              formPage={formPage}
              step={step}
            />
          </form>
        </div>
      </div>
    )
  }
}

Page2 = reduxForm({
  form: 'ListPlaceStep1',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: update
})(Page2);


const selector = formValueSelector('ListPlaceStep1');

const mapState = (state) => ({
  existingList: state?.location?.isExistingList,
  listingFields: state?.listingFields?.data,
  makeName: selector(state, 'make'),
  modelValue: selector(state, 'model'),
  typeId: selector(state, 'typeId'),
});

const mapDispatch = {
};

export default injectIntl(withStyles(s, cs)(connect(mapState, mapDispatch)(Page2)));
