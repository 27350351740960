import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, reset, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import FormControl from 'react-bootstrap/lib/FormControl';
import Loader from '../Loader/Loader';
import messages from '../../locale/messages';
import validate from './validate';
import { sendEmail } from '../../core/email/sendEmail';
import { googleCaptcha } from '../../config';
import showToaster from '../../helpers/toasterMessages/showToaster';
import mailLogo from './mailblack.svg';
import caller from './callLogo.svg';
import addressLogo from './address.svg';
import s from './SeguroForm.css';
import triunfo from '../../../public/SiteIcons/triunfo.png';

class SeguroForm extends Component {
    static propTypes = {
        formatMessage: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            contactLoading: false,
            genericTypeArray: ['seleccione uno', 'auto/pick up', 'pick up B', 'moto', 'camion', 'acoplado/semiremolque', 'trailer', 'casa rodante'],
            tipeArray: ['seleccione uno', 'Automovil', 'Pickup', 'Moto'],
            origenArray: ['seleccione uno', 'Nacional', 'Importado'],
            usoArray: ['seleccione uno', 'Personal', 'Comercial'],
            coberturasArray: ['seleccione uno', 'terceros', 'terceros completos', 'todo riesgo'],
            accesoriosArray: ['seleccione uno', 'Aire Acndicionado', 'Eqip. GNC T.', 'Equip. GNC T.P.', 'GNC no asegurado'],
            gpsArray: ['seleccione uno', 'propio', 'sin GPS'],
            accesorios: [],
            adicionales: [],

        };
    }

    generateYearsOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1935;
        let years = [];
        years.push(<option key={1} value={'seleccione año'}>seleccine año</option>);
        for (let i = currentYear; i >= startYear; i--) {
            years.push(<option key={i} value={i}>{i}</option>);
        }

        return years;
    };

    generateCheckboxesFromArray = (array) => {
        return array.map((item, index) => (
            <div key={index} >
                <label>
                    <input type="checkbox" value={item} /> {item}
                </label>
            </div>
        ));
    }



    generateOptionFromArray = (array) => {
        return array.map((item, index) => {
            return <option
                key={index}
                value={item}
                className={s.selectOption}
            >
                {item}
            </option>
        })
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, isDisabled, children, isCheckboxGroup, placeholder }) => {
        const { formatMessage } = this.props.intl;

        // Si es un grupo de checkboxes, renderiza los checkboxes en lugar del select o input
        if (isCheckboxGroup) {
            return (
                <div>
                    <label>{label}</label>
                    <div className={className} disabled={isDisabled}>
                        {children}
                    </div>
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                </div>
            );
        }

        // Si hay children, renderiza un select
        if (children) {
            return (
                <div>
                    <label>{label}</label>
                    <select {...input} className={className} disabled={isDisabled}>
                        {children}
                    </select>
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                </div>
            );
        }

        // Si no, renderiza un input de tipo FormControl
        return (
            <div>
                <FormControl
                    {...input}
                    placeholder={placeholder ? formatMessage(placeholder) : label}
                    type={type}
                    className={className}
                    disabled={isDisabled} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        );
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <FormControl
                    {...input}
                    className={className}
                    componentClass="textarea"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        )
    }

    renderCaptcha = ({ input, label, type, meta: { touched, error }, className, isDisabled }) => {
        const { formatMessage } = this.props.intl;
        let siteKey = googleCaptcha.sitekey;
        return (
            <div>
                <ReCAPTCHA
                    sitekey={siteKey}
                    onChange={input.onChange}
                />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        )
    }

    addAccesorio = () => {
        console.log('addAccesorio', this.props.formValues);
        const { accesorios } = this.state;
        const accesorioName = this.props.formValues.accesorio; // Obtén el valor seleccionado del accesorio
        const accesorioValue = this.props.formValues.importe;  // Obtén el valor del importe

        // Verifica que el accesorio y el importe no estén vacíos
        if (accesorioName && accesorioName !== 'seleccione uno' && accesorioValue) {
            const nuevoAccesorio = {
                name: accesorioName,
                value: accesorioValue
            };

            // Agrega el nuevo accesorio al array de accesorios
            this.setState({
                accesorios: [...accesorios, nuevoAccesorio],
            });
            console.log('accesorios', this.state.accesorios);
            this.props.change('accesorio', '');  // Limpia el campo accesorio
            this.props.change('importe', ''); 
        }
    }

    // Función para agregar un adicional
    addAdicional = () => {
        const { adicionales } = this.state;
        const adicionalName = this.props.formValues.adicional;      // Obtén el valor del adicional
        const adicionalValue = this.props.formValues.importeAdicional; // Obtén el valor del importe

        // Verifica que el adicional y el importe no estén vacíos
        if (adicionalName && adicionalValue) {
            const nuevoAdicional= {
                name: adicionalName,
                value: adicionalValue
            }

            // Agrega el nuevo adicional al array de adicionales
            this.setState({
                adicionales: [...adicionales, nuevoAdicional],
            });
            console.log('adicionales', this.state.adicionales);
            this.props.change('adicional', '');  // Limpia el campo adicional
            this.props.change('importeAdicional', ''); // Limpia el campo importe
        }
    }

    handleClick = async (values, dispatch) => {
        const { accesorios, adicionales } = this.state;
        const { email } = this.props;

        // Aquí tienes todos los valores que provienen del formulario (incluyendo los selects)
        let content = {
            siteName: 'Hacela Simple',            // Nombre del sitio
            phoneNumber: values.phoneNumber,        // Número de teléfono
            name: values.name,                     // Nombre
            lastName: values.lastName,             // Apellido
            email: values.email,                   // Email
            contactMessage: values.contactMessage, // Mensaje de contacto
            make: values.make,                     // Marca del vehículo
            model: values.model,                   // Modelo del vehículo
            carUsage: values.usage,                // Uso del vehículo
            year: parseInt(values.year),                     // Año seleccionado
            origin: values.origin,                 // Origen seleccionado
            genericType: values.GenericType,       // Tipo genérico del vehículo
            carCategoryVariant: values.carCategoryVarian, // Variante de categoría del vehículo
            numberPlate: values.numberPlate,       // Número de placa
            vin: values.vin,                       // Número VIN del vehículo
            vehicleValue: parseInt(values.vehicleValue,),   // Valor del vehículo
            coverage: values.coverage,             // Cobertura seleccionada
            gps: values.gps,                       // GPS seleccionado
            accesorios: accesorios,               // Accesorios agregados
            adicionales: adicionales               // Adicionales agregados
        };

        // Envía todos los datos (valores del formulario + accesorios/adicionales)
        this.setState({
            contactLoading: true
        });

        const { status, response } = await sendEmail(email, 'insuranceContact', content);

        this.setState({
            contactLoading: false
        });

        if (status === 200) {
            showToaster({ messageId: 'recibimos tu mail', toasterType: 'success' });
        } else {
            showToaster({ messageId: 'no se puedo enviar', toasterType: 'error' });
        }

        dispatch(reset('SeguroForm'));
        this.state.accesorios = [];
        this.state.adicionales = [];
        grecaptcha.reset();
    }


    render() {
        const { error, handleSubmit, submitting, dispatch, pristine } = this.props;
        const { formatMessage } = this.props.intl;
        const { contactLoading, tipeArray, gpsArray, usoArray, accesoriosArray, coberturasArray, genericTypeArray, origenArray } = this.state;
        const title = <h3>{formatMessage(messages.Required)}</h3>;
        const { email, phoneNumber, address } = this.props;
        return (
            <Grid fluid>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.marginTop)}>
                        <div className={cx(s.formBackground, 'inputFocusColor')}>
                            <div className={s.formContainerHeader}>
                                <img src={triunfo} />
                                <h2 className={s.captionText}><FormattedMessage {...messages.insuranceForm} /></h2>
                            </div>
                            <div className={s.formContainer}>
                                {error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                                <form onSubmit={handleSubmit(this.handleClick)} >
                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={6} md={6} lg={6} className={s.noPadding}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.nameInContact)}</label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field name="name"
                                                    type="text"
                                                    component={this.renderFormControl}
                                                    label={formatMessage(messages.nameInContact)}
                                                    className={cx(s.formControlInput, s.backgroundTwo, 'contactInputRTL')}
                                                />
                                            </Col>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6} className={s.noPadding}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.lasNameInContact)}</label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field name="lastName"
                                                    type="text"
                                                    component={this.renderFormControl}
                                                    label={formatMessage(messages.lasNameInContact)}
                                                    className={cx(s.formControlInput, s.backgroundTwo, 'contactInputRTL')}
                                                />
                                            </Col>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6} className={s.noPadding}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.phoneNumber)}</label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Field name="phoneNumber"
                                                    type="text"
                                                    component={this.renderFormControl}
                                                    label={formatMessage(messages.phoneNumber)}
                                                    className={cx(s.formControlInput, s.backgroundThree, 'contactInputRTL')}
                                                />
                                            </Col>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <label className={s.labelText} >{formatMessage(messages.email)}</label>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Field name="email"
                                                type="text"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.email)}
                                                className={cx(s.formControlInput, s.backgroundOne, 'contactInputRTL')}
                                            />
                                        </Col>
                                    </Row>

                                    <h3 className={s.subTitleText}> Datos del Vehiculo </h3>

                                    <Row className={s.formGroup}>

                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Field
                                                name="year"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.year)}
                                                className={cx(s.formControlInput, s.backgroundCalendar, 'yearInputRTL')}
                                            >
                                                {this.generateYearsOptions()} {/* Pasa las opciones de año */}
                                            </Field>

                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>

                                            <Field
                                                name="origin"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.carOrigin)}
                                                className={cx(s.formControlInput, s.backgroundCalendar, 'yearInputRTL')}
                                            >
                                                {this.generateOptionFromArray(origenArray)} {/* Pasa las opciones de origen */}
                                            </Field>

                                        </Col>

                                    </Row>

                                    <h3 className={s.subTitleText}> Marca / Modelo </h3>

                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.makeLabel)}</label>
                                            </Col>
                                            <Field
                                                name="make"
                                                type="text"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.makeLabel)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'makeInputRTL')}
                                            />

                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.modelLabel)}</label>
                                            </Col>
                                            <Field
                                                name="model"
                                                type="text"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.modelLabel)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'modelInputRTL')}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>

                                            <Field
                                                name="GenericType"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.genericType)}
                                                className={cx(s.formControlInput, s.backgroundCalendar, 'genericTypeInputRTL')}
                                            >
                                                {this.generateOptionFromArray(genericTypeArray)}
                                            </Field>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>

                                            <Field
                                                name="usage"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.carUsage)}
                                                className={cx(s.formControlInput, s.backgroundCalendar, 'usageInputRTL')}
                                            >
                                                {this.generateOptionFromArray(usoArray)}
                                            </Field>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.carCategoryVariant)}</label>
                                            </Col>
                                            <Field name="carCategoryVarian"
                                                type="text"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.carCategoryVariant)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'carCategoryVariantInputRTL')}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.numberPlate)}</label>
                                            </Col>
                                            <Field name="numberPlate"
                                                type="text"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.numberPlate)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'numberPlateInputRTL')}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.vin)}</label>
                                            </Col>
                                            <Field name="vin"
                                                type="text"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.vin)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'vinInputRTL')}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={s.labelText} >{formatMessage(messages.vehicleValue)}</label>
                                            </Col>
                                            <Field name="vehicleValue"
                                                type="number"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.vehicleValue)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'vehicleValueInputRTL')}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>

                                            <Field
                                                name="coverage"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.coverage)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'coverageInputRTL')}
                                            >
                                                {this.generateOptionFromArray(coberturasArray)}

                                            </Field>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>

                                            <Field
                                                name="gps"
                                                component={this.renderFormControl}
                                                label={formatMessage(messages.gps)}
                                                className={cx(s.formControlInput, s.backgroundFour, 'gpsInputRTL')}
                                            >
                                                {this.generateOptionFromArray(gpsArray)}

                                            </Field>
                                        </Col>

                                    </Row>

                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3 className={s.subTitleText}>Accesorios</h3>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <label className={s.labelText}>Accesorio</label>

                                            {/* Reemplazamos el input de texto por un select con las opciones de accesorios */}
                                            <Field
                                                name="accesorio"
                                                component={this.renderFormControl}
                                                className={cx(s.formControlInput, s.backgroundFour)}
                                            >
                                                {this.generateOptionFromArray(accesoriosArray)} {/* Usamos las opciones del array */}
                                            </Field>
                                        </Col>

                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <label className={s.labelText}>Importe</label>
                                            <Field
                                                name="importe"
                                                type="number"
                                                component={this.renderFormControl}
                                                className={cx(s.formControlInput, s.backgroundFour)}
                                            />
                                        </Col>

                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <button 
                                            type="button" 
                                            onClick={this.addAccesorio} 
                                            className={s.button}
                                            disabled={!this.props.formValues || !this.props.formValues.accesorio || !this.props.formValues.importe}
                                            >
                                                {formatMessage(messages.addAccesory)}
                                            </button>
                                        </Col>
                                    </Row>

                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3 className={s.subTitleText}>Adicionales</h3>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <label className={s.labelText}>Adicional</label>
                                            <Field
                                                name="adicional"
                                                type="text"
                                                component={this.renderFormControl}
                                                className={cx(s.formControlInput, s.backgroundFour)}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <label className={s.labelText}>Importe</label>
                                            <Field
                                                name="importeAdicional"
                                                type="number"
                                                component={this.renderFormControl}
                                                className={cx(s.formControlInput, s.backgroundFour)}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <button
                                                type="button"
                                                onClick={this.addAdicional}
                                                className={s.button}
                                                disabled={!this.props.formValues || !this.props.formValues.adicional || !this.props.formValues.importeAdicional}
                                                >
                                                    {formatMessage(messages.addAdditional)}</button>
                                        </Col>
                                    </Row>

                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3 className={s.subTitleText}>{formatMessage(messages.addedAdditionals)}</h3>
                                            <ul>
                                                {this.state.accesorios.length > 0 ? (
                                                    this.state.accesorios.map((item, index) => (
                                                        <li key={index}>
                                                            {item.name} - Importe: {item.value}
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No se han agregado accesorios</li>
                                                )}
                                            </ul>
                                        </Col>
                                    </Row>

                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <h3 className={s.subTitleText}>{formatMessage(messages.addedAdditionals)}</h3>
                                            <ul>
                                            {this.state.adicionales.length > 0 ? (
                                                    this.state.adicionales.map((item, index) => (
                                                        <li key={index}>
                                                            {item.name} - Importe: {item.value}
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No se han agregado accesorios</li>
                                                )}
                                            </ul>
                                        </Col>
                                    </Row>




                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <label className={s.labelText} >{formatMessage(messages.insuranceContactMessage)}</label>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Field name="contactMessage"
                                                type="text"
                                                component={this.renderFormControlTextArea}
                                                label={formatMessage(messages.insuranceContactMessage)}
                                                className={cx(s.formControlInput, s.backgroundFour, s.textAreaBorder, 'contactMessageInputRTL')}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12} className={cx(s.overFlowHidden, s.spaceTop1)}>
                                            <Field name="reCaptcha"
                                                component={this.renderCaptcha}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={s.formGroup}>
                                        <Col xs={12} sm={12} md={12} lg={12} className={s.spaceTop3}>
                                            <Loader
                                                type={"button"}
                                                buttonType={"submit"}
                                                className={cx(s.button, s.btnPrimary, s.btnlarge)}
                                                disabled={submitting}
                                                show={contactLoading}
                                                label={formatMessage(messages.sendmail)}
                                            />
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={s.marginTop}>
                        <div>
                            {(email || phoneNumber || address) && <div className={s.space6}>
                                <h1 className={s.contactTitle}>
                                    <FormattedMessage {...messages.contactFormInformation} />
                                </h1>
                            </div>}
                            <Row className={s.dFlex}>
                                {email && <Col lg={4} md={4} sm={4} xs={12} className={s.alignCenter}>
                                    <div className={s.space6}>
                                        <div>
                                            <div className={s.iconMargin}>
                                                <img src={mailLogo} className={s.mailIcon} />
                                            </div>
                                            <div>
                                                <h1 className={cx(s.contactTitle, s.subTitleText)}>
                                                    <FormattedMessage {...messages.contactFormEmail} />
                                                </h1>
                                                <a href={"mailto:" + email} className={s.linkText} target='_blank'>{email}</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>}
                                {phoneNumber && <Col lg={4} md={4} sm={4} xs={12} className={s.alignCenter}>
                                    <div className={s.space6}>
                                        <div>
                                            <div className={s.iconMargin}>
                                                <img src={caller} className={s.mailIcon} />
                                            </div>
                                            <div>
                                                <h1 className={cx(s.contactTitle, s.subTitleText)}><FormattedMessage {...messages.contactFormCall} /></h1>
                                                <a href={"tel:" + phoneNumber} className={s.linkText} target='_blank'>
                                                    {phoneNumber}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>}
                                {address && <Col lg={4} md={4} sm={4} xs={12} className={s.alignCenter}>
                                    <div className={s.space6}>
                                        <div>
                                            <div className={s.iconMargin}>
                                                <img src={addressLogo} className={s.mailIcon} />
                                            </div>
                                            <h1 className={cx(s.contactTitle, s.subTitleText)}>
                                                <FormattedMessage {...messages.contactFormAddress} />
                                            </h1>
                                            <h4 className={s.addressText}>
                                                {address}
                                            </h4>
                                        </div>
                                    </div>
                                </Col>}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Grid>
        )
    }

}

SeguroForm = reduxForm({
    form: 'SeguroForm', // a unique name for this form
    validate
})(SeguroForm);


const mapState = (state) => ({
    email: state?.siteSettings?.data?.email,
    phoneNumber: state?.siteSettings?.data?.phoneNumber,
    address: state?.siteSettings?.data?.address,
    formValues: getFormValues('SeguroForm')(state)
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SeguroForm)));
