import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import EmailSignature from './EmailSignature';
import { urlMail } from '../../../config';
import { COMMON_COLOR, COMMON_TEXT_COLOR } from '../../../constants/index';
import { text } from 'body-parser';


class BookingConfirmationGuest extends React.Component {
    static propTypes = {
        content: PropTypes.shape({
            guestName: PropTypes.string.isRequired,
            hostName: PropTypes.string.isRequired,
            listTitle: PropTypes.string.isRequired,
            listCity: PropTypes.string.isRequired,
            threadId: PropTypes.number.isRequired,
            siteName: PropTypes.string.isRequired,
			reservationAmount: PropTypes.number.isRequired,
			amountToPay: PropTypes.number.isRequired,
            extrasList: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                    name: PropTypes.string.isRequired,
                    price: PropTypes.number.isRequired,
                })
            ),
        }).isRequired
    };


	render() {
		const textStyle = {
			color: COMMON_TEXT_COLOR,
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px',
		};
		
		const strongTitle = {
			color: COMMON_COLOR,
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '14px',
			padding: '35px',
			textAlign: 'center',
		};

		const linkText = {
			color: COMMON_COLOR,
			fontSize: '16px',
			textDecoration: 'none',
			cursor: 'pointer',
		}

		const listItemSection = {
			display: 'inline-block',
			width: '50%',
		  }

		  const listStyle = {
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            listStyleType: 'none'
        };

        const itemStyle = {
            padding: '10px',
            borderBottom: '1px solid #ddd'
        };

        const priceStyle = {
            color: '#555',
            fontWeight: 'bold',
            float: 'right'
        };
		const styledquote = {
			borderLeft: '4px solid #007BFF', 
			paddingLeft: '20px', 
			margin: '20px 0', 
			fontStyle: 'italic', 
			color: '#333', 
			backgroundColor: '#f9f9f9',
			borderRadius: '8px',
			fontFamily: 'Georgia, serif',
			lineHeight: '1.5',
		}
		

		const { content: { guestName, hostName, listTitle, listCity, threadId, logo, siteName, extrasList, reservationAmount, amountToPay } } = this.props;
		let contactURL = urlMail ? urlMail : "https://www.hacelasimple.com" + '/message/' + threadId + '/renter';
		
	
		return (
			<Layout>
				<Header
					color="rgb(255, 90, 95)"
					backgroundColor="#F7F7F7"
					logo={logo}
					siteName={siteName}
				/>
				<div>
					<Table width="100%" >
						<TBody>
							<TR>
								<TD style={textStyle}>
									<EmptySpace height={20} />
									<div>
										Hola {guestName},
									</div>
									<EmptySpace height={20} />
									<div>
									Preparate, todo esta listo para tu viaje.
									</div>
									<EmptySpace height={20} />
									<div>
										{hostName} confirmó tu solicitud. Por favor, revisa los detalles de tu viaje y
										{' '}<a style={linkText} href={contactURL}>contacta al dueño</a>{' '} para coordinar los horarios del viaje y la entrega de llaves del vehículo.
									</div>
									<EmptySpace height={20} />
									<div>
										<EmptySpace height={20} />
										<strong style={strongTitle}>Detalles del viaje:</strong>
									</div>
									<EmptySpace height={20} />
									<div>
										<strong >Abonaste: </strong>
										${reservationAmount}
									</div>
									<EmptySpace height={20} />
									<div>
										<strong>Saldo pendiente: </strong>
										${amountToPay}
										<blockquote style={styledquote}>
											Recorda que el saldo pendiente debe ser abonado directamente al dueño antes de comenzar el viaje.
										</blockquote>
									</div>
									<div>
									
									</div>
									{extrasList && extrasList.length > 0 && (
											<>
											<EmptySpace height={40} />						
											<strong style={strongTitle}>Has agregado a tu viaje estos extras:</strong>
                    					<ul style={listStyle}>
                    					    {extrasList.map(extra => (
                    					        <li key={extra.id} style={itemStyle}>
                    					            {extra.name}
                    					            <span style={priceStyle}>${extra.price}</span>
                    					        </li>
                    					    ))}
                    					</ul>
										</>
                						)}
									<EmptySpace height={20} />
									<EmailSignature siteName={siteName} />
								</TD>
							</TR>
						</TBody>
					</Table>
					<EmptySpace height={40} />
				</div>
				<Footer siteName={siteName} />
				<EmptySpace height={20} />
			</Layout>
		);
	}
}

export default BookingConfirmationGuest;
