// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  FormGroup,
} from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import fetch from '../../core/fetch';



import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';

import CustomCheckbox from '../CustomCheckbox';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

import update from './update';
import messages from '../../locale/messages';
import validate from './validate';
class Page8 extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    formErrors: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      carFeatures: [],
      otherVehicleFeature: [],
      isDisabled: false,
      typeId: null
    }
  }

  componentDidMount() {
    const query = `
        query GetListingTypeId($id: Int!) {
          GetListingTypeIdByListingId(id: $id) {
            typeId
          }
        }
      `;
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const listingId = pathParts[2];
  
    fetch('/graphql', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: query,
        variables: { id: parseInt(listingId) }
      }),
      credentials: 'include'
    })
      .then(res => res.json())
      .then(response => {
        const typeId = response.data.GetListingTypeIdByListingId.typeId;
        this.setState({ typeId });
        change('typeId', typeId);
        if (typeId !== 20 && this.props.initialValues && this.props.initialValues.otherVehicleFeature) {
          this.props.change('carFeatures', this.props.initialValues.otherVehicleFeature); // Establece valores iniciales
        }
      })
      .catch(error => {
        console.error('Error fetching typeId:', error);
      });
  
    const { formErrors, listingFields } = this.props;
    console.log('formErrors on mount:', formErrors);
  
    if (formErrors && formErrors.syncErrors) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }
  
    if (listingFields) {
      this.setState({
        carFeatures: listingFields.carFeatures,
        otherVehicleFeature: listingFields.otherVehicleFeature
      });
    }
  }
  
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const { formErrors, listingFields } = nextProps;
  //   console.log('formErrors on receive props:', formErrors);
  
  //   if (formErrors && formErrors.syncErrors) {
  //     this.setState({ isDisabled: true });
  //   } else {
  //     this.setState({ isDisabled: false });
  //   }
  
  //   if (listingFields) {
  //     this.setState({
  //       otherVehicleFeature: listingFields.otherVehicleFeature,
  //       carFeatures: listingFields.carFeatures,
  //     });
  //   }
  // }
  

  checkboxGroup = ({ options, input }) => (
    <ul className={s.listContainer}>
      {options.map((option, index) => {
        if (option.isEnable === "1") {
          return (
            <li className={cx(s.listContent, cs.spaceBottom3)} key={index}>
              <span>
                <CustomCheckbox
                  name={`${input.name}[${index}]`}
                  value={option.id}
                  className={'icheckbox_square-green'}
                  checked={input.value.indexOf(option.id) !== -1}
                  onChange={event => {
                    const newValue = [...input.value];
                    if (event === true) {
                      newValue.push(option.id);
                    } else {
                      newValue.splice(newValue.indexOf(option.id), 1);
                    }
                    return input.onChange(newValue);
                  }}
                />
              </span>
              <span className={cx(s.checkBoxLabel)}>
                <label className={cx(cs.commonMediumText, cs.fontWeightNormal)}>{option.itemName}</label>
              </span>
            </li>
          )
        }
      }
      )
      }
    </ul>
  );

  render() {
    const { handleSubmit, previousPage, formPage, step, listingFields } = this.props;
    const { isDisabled, carFeatures, otherVehicleFeature, typeId } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step1HeadingNew)}
          landingContent={formatMessage(messages.whatamenities)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}>
              <FormattedMessage {...messages.carFeatures} />
            </h3>
            {!typeId ? (<p>loading...</p>) : (<FormGroup className={s.formGroup}>
              <Field 
                name="carFeatures" 
                component={this.checkboxGroup} 
                options={typeId == 20 ? carFeatures: otherVehicleFeature} />
            </FormGroup>) }
          </div>
          <FooterButton
            isDisabled={isDisabled}
            previousPage={previousPage}
            previousPagePath={"map"}
            type={"submit"}
            formPage={formPage}
            step={step}
            isFinish
          />
        </form>
      </div>
    );
  }
}

Page8 = reduxForm({
  form: 'ListPlaceStep1', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: (values, dispatch) => {
    update(values, dispatch);
  }
})(Page8);

const selector = formValueSelector('ListPlaceStep1');

const mapState = (state) => ({
  userData: state.account.data,
  formErrors: state.form.ListPlaceStep1,
  listingFields: state.listingFields.data,
  typeId: selector(state, 'typeId'),
});

const mapDispatch = {
};

export default injectIntl(withStyles(s, cs)(connect(mapState, mapDispatch)(Page8)));
