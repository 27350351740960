import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EmailSignature extends Component {
    static propTypes = {
        siteName: PropTypes.string.isRequired,
    }
    render() {
        const { siteName } = this.props;
        return (
            <div style={{display:'flex', flexDirection: 'column'}}>
                ¡Muchas gracias!, <br />
                El equipo de {siteName}
            </div>
        )
    }
}

export default EmailSignature;