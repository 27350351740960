import React from 'react';
import Layout from '../../../components/Layout';

import AccordionComponent from '../../../components/Accordion/AccordionComponent';


export default async function action({ locale }) {

  return {
    title: "FAQ",
    description: "preguntas frecuentes",
    chunk: 'faq',
    component: <Layout>
      <AccordionComponent />
    </Layout>


  };

};
