import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import s from './TriunfoBanner.css';
import l from '../../Skeleton/Skeleton.css'

import triunfoLogo from '../../../../public/SiteIcons/triunfo.png'

import messages from '../../../locale/messages';

class TriunfoBanner extends React.Component {

  static propTypes = {

  };

  static defaultProps = {

  }

  render() {

    return (
      <>



      

<div className={s.topBottomCss}>
  <div className={cx(s.container, s.flexContainer)}>
    <img src={triunfoLogo} className={s.logoImage} alt="Triunfo Seguros Logo"></img>
    <h3 className={cx(s.containerTitle)}>
      <a href='/triunfo'>
        <FormattedMessage {...messages.triunfoAconseja} />
      </a>
    </h3>
  </div>
</div>

      </>
    )
  }
}

export default compose(
  injectIntl,
  withStyles(s, l)
)(TriunfoBanner);
