exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.Accordion-accordion-2aCyl {\n  width: 100%;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  overflow: hidden;\n  /* Asegura que el contenido no se desborde */\n}\n\n.Accordion-accordionHeader-1pX7e {\n  padding: 15px;\n  cursor: pointer;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  font-size: 16px;\n  font-weight: bold;\n  background-color: #f1f1f1;\n  /* Color de fondo para diferenciar */\n  border-bottom: 1px solid #ccc;\n}\n\n.Accordion-accordionBody-1uXxa {\n  padding: 15px;\n  background-color: #f9f9f9;\n  font-size: 14px;\n  overflow: hidden;\n  /* Asegura que el contenido no se desborde */\n}\n\n.Accordion-accordionIcon-10kCY {\n  -webkit-transition: -webkit-transform 0.3s;\n  transition: -webkit-transform 0.3s;\n  -o-transition: transform 0.3s;\n  transition: transform 0.3s;\n  transition: transform 0.3s, -webkit-transform 0.3s;\n}\n\n.Accordion-rotate-2LQxX {\n  -webkit-transform: rotate(180deg);\n      -ms-transform: rotate(180deg);\n          transform: rotate(180deg);\n}\n\n.Accordion-accordionItem-1PT31 {\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  -ms-flex-negative: 1;\n      flex-shrink: 1;\n  -ms-flex-preferred-size: calc(50% - 20px);\n      flex-basis: calc(50% - 20px);\n  /* Ocupa el 50% del espacio menos el gap */\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  margin-top: 2px;\n  margin-bottom: 8px;\n}", ""]);

// exports
exports.locals = {
	"accordion": "Accordion-accordion-2aCyl",
	"accordionHeader": "Accordion-accordionHeader-1pX7e",
	"accordionBody": "Accordion-accordionBody-1uXxa",
	"accordionIcon": "Accordion-accordionIcon-10kCY",
	"rotate": "Accordion-rotate-2LQxX",
	"accordionItem": "Accordion-accordionItem-1PT31"
};