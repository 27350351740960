
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';
import s from './Failure.css';


 class Failure extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    bname: PropTypes.string.isRequired,
    cardnumber: PropTypes.string.isRequired,
    failreason: PropTypes.string.isRequired,
  }



  render() {
    const { bname, cardnumber, failreason } = this.props;

    return (
      <div className={s.container}>
          <div>
            <h1 className={s.containerTitle}>{bname}, hubo un problema con tu pago.</h1>
            <h2 className={s.containerSubTitle}>El pago con tu tarjeta {cardnumber} no fue aprobado</h2>
            <h2 className={s.containerSubTitle}>debido a " {failreason} " </h2>
            <p className={s.containerParagrph}> Vuelve a intentar mas tarde.</p>
            <div className={s.buttonContainer}>
              <a className={s.backBtn} href={"/"} >
                Ir a la pagina principal
              </a>
              </div>
          </div>
        
      </div>
    )
  }
}



export default compose(
    withStyles(s)(Failure)
  );