import React from 'react';
import s from './Install.css';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../locale/messages';

class Install extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInstallable: false,
      deferredPrompt: null,
      isVisible: true,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', this.handleAppInstalled);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.removeEventListener('appinstalled', this.handleAppInstalled);
  }

  handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    this.setState({
      deferredPrompt: e,
      isInstallable: true,
    });
  };

  handleAppInstalled = () => {
    this.setState({
      deferredPrompt: null,
      isInstallable: false,
      isVisible: false,
    });
  };

  handleInstallClick = async () => {
    const { deferredPrompt } = this.state;
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      if (outcome === 'accepted') {
        this.setState({
          isInstallable: false,
          deferredPrompt: null,
          isVisible: false,
        });
      }
    }
  };

  render() {
    const { isInstallable, isVisible } = this.state;

    if (!isInstallable || !isVisible) {
      return null;
    }

    return (
      <div className={s.installb}>
        <button
          onClick={this.handleInstallClick}
          className={s.installButtonStyle}
          style={{ marginBottom: '10px' }} // Cambiado para separación vertical
        >
          <FormattedMessage {...messages.installApplication} />
        </button>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="white" // Fondo blanco
          viewBox="0 0 30 30"
          stroke="currentColor"
          strokeWidth={2}
          className={s.arrowDown}
        >
          <circle cx="15" cy="15" r="12" stroke="currentColor" strokeWidth="2" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 10v8m0 0l-4-4m4 4l4-4" // Flecha hacia abajo
            strokeWidth="2"
          />
        </svg>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(Install));
