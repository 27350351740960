import axios from "axios";


const dolarRatesAR = async () => {

  try {
    const response = await axios.get("https://dolarapi.com/v1/dolares", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }

};

export default dolarRatesAR;