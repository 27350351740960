import { useState, useEffect } from "react"
import React from 'react'

import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';

import s from './SecurityDeposit.css';

function securityDeposit() {

  return (
    <div className={s.container}>
      <h1 className={s.containerTitle}>¿Qué es el depósito de garantía?</h1>
      <div className={s.sectionWidth}>
        <h2 className={s.containerSubTitle}> 
          Aquí te contamos qué es y cómo lo gestionamos:
        </h2>
        <p className={s.containerParagraph}> 
        En Hacela Simple, nos esforzamos por brindar seguridad y tranquilidad tanto a los propietarios de vehículos como a nuestros clientes. 
        Por eso hemos implementado un depósito de seguridad para proteger a ambas partes.<br/>
        <h3 className={s.containerSubTitle}>¿Qué es el depósito de seguridad?</h3>
        Al aceptar nuestros términos y condiciones y realizar el pago de la reserva, autorizas a Hacela Simple a retener un saldo de USD 300 en tu tarjeta de crédito. 
        Este monto solo se utilizará en caso de siniestro o infracción durante el período de alquiler. 
        Si no ocurren incidentes, no se realizará ningún cargo adicional en tu tarjeta.<br/>
        <b>Es importante destacar</b> que, en caso de infracciones, los organismos públicos pueden tardar hasta 30 días 
        en notificar al propietario del vehículo.<br/> 
        Por lo tanto, mantenemos el depósito durante este período máximo para asegurarnos de cubrir cualquier eventualidad.<br/>

        Nuestro objetivo es brindarte una experiencia de alquiler sin preocupaciones.<br/> 
        Confía en nosotros para que tu experiencia sea simple y segura.

        </p>
      </div>
    </div>
  )
}

export default compose(
  withStyles(s)(securityDeposit)
);
