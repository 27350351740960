import React from 'react';
import Triunfo from './Triunfo';
import fetch from '../../core/fetch';

import Layout from '../../components/Layout/Layout';

import { getListingFields } from '../../actions/getListingFields';

export default async function action({ store }) {
  const title = store.getState().siteSettings.data.siteTitle;
  const description = store.getState().siteSettings.data.metaDescription;
  const listingFields = store.getState().listingFields.data;
  const layoutType = store.getState().siteSettings.data.homePageType;
  const wholeData = store.getState().homeBannerImages.data;

  if (listingFields === undefined) {
    store.dispatch(getListingFields());
  }

  return {
    title,
    description,
    listingFields,
    chunk: 'triunfoSeguros',
    component: <Layout 
    layoutType={layoutType}>
      <Triunfo 
      layoutType={layoutType} 
      wholeData={wholeData} />
      </Layout>,
  };
};
