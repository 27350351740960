import React from 'react';
import Layout from '../../components/Layout';
import Success from './success';



export default function action({params}) {
  const title = 'fiserv success';
  
      let hosteddataid = '';
      let bname = '';
      let cardnumber = '';

      hosteddataid = params.hosteddataid;
      bname = params.bname;
      cardnumber = params.cardnumber
    
  return {
    title,
    component: 
    <Layout>
      <Success
      title={title}
      hosteddataid={hosteddataid}
      bname={bname}
      cardnumber={cardnumber}
      />,
    </Layout>,
  };
}