import { useState, useEffect } from "react"
import React from 'react'

import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';

import s from './SecurityDepositOwner.css';

function securityDeposit() {

  return (
    <div className={s.container}>
       <hr/>
      <h1 className={s.containerTitle}>¿Qué es el depósito de garantía?</h1>
      <hr/>
      <div className={s.sectionWidth}>
        <h2 className={s.containerSubTitle}> 
          Aquí te contamos qué es y cómo lo gestionamos:
        </h2>
        <p className={s.containerParagraph}> 
        En Hacela Simple, nos esforzamos por brindar seguridad y tranquilidad tanto a los propietarios de vehículos como a nuestros clientes. 
        Por eso hemos implementado un depósito de seguridad para proteger a ambas partes.<br/>
        <hr/>
        <h3 className={s.containerSubTitle}>¿Qué es el depósito de seguridad?</h3>
        <hr/>
        •	¿Qué sucede si ocurre un siniestro que el seguro no cubre el arreglo?<br/>
        <hr/>
        Para esta situación contamos con el deposito de seguridad que se retiene de la tarjeta de crédito del rentador, 
        en caso de siniestro vamos a utilizar el saldo para subsanar el problema, 
        siempre y cuando el seguro no lo cubra. Ver bases y condiciones <br/>
        <hr/>
        •	¿Cómo funciona el sistema de pago de depósito de seguridad?<br/>
        <hr/>
        Ante un siniestro o infracción, el propietario del vehículo debe comunicarse con Hacela Simple 
        para que intervengamos en el caso donde informaremos sobre el inconveniente a quien alquilo el vehículo, 
        previamente a realizar el cobro del seguro de su tarjeta de crédito para luego realizar el pago al propietario.<br/> 
        De esta manera se subsanara en caso de infracción o siniestro. 

        </p>
      </div>
    </div>
  )
}

export default compose(
  withStyles(s)(securityDeposit)
);
