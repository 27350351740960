import fetch from '../../core/fetch';

const extractListData = async (listId) => {
    const getListUserId = `
      query GetListMeta($listId: Int!) {
        getListMeta(listId: $listId) { 
          userId
        }
      }
    `;
  
    const respList = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: getListUserId,
        variables: { listId }
      }),
    });
  
    const { data } = await respList.json();
    //onsole.log('data', JSON.stringify(data));
  
    // Accede a userId y devuélvelo
    return data?.getListMeta?.userId;
  }
  
  export default extractListData;
  