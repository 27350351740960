exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AccordionComponent-container-1WwS3 {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.AccordionComponent-faqTitle-WuHNF {\n  text-align: center;\n  margin-bottom: 20px;\n  font-size: 32px;\n  font-size: 2rem;\n  font-weight: bold;\n}\n\n.AccordionComponent-accordionColumns-fCGkO {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  gap: 20px; /* Espacio entre las dos columnas */\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap; /* Permite que los elementos se muevan a la siguiente línea en pantallas pequeñas */\n}\n\n.AccordionComponent-accordionColumn-Qyfcq {\n  -webkit-box-flex: 1;\n      -ms-flex: 1 1;\n          flex: 1 1; /* Permite que las dos columnas tengan el mismo ancho */\n  min-width: 300px; /* Ancho mínimo para que las columnas no se reduzcan demasiado */\n}\n\n/* Media query para pantallas pequeñas */\n\n@media (max-width: 768px) {\n  .AccordionComponent-accordionColumns-fCGkO {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column; /* Coloca las columnas una debajo de la otra */\n  }\n}", ""]);

// exports
exports.locals = {
	"container": "AccordionComponent-container-1WwS3",
	"faqTitle": "AccordionComponent-faqTitle-WuHNF",
	"accordionColumns": "AccordionComponent-accordionColumns-fCGkO",
	"accordionColumn": "AccordionComponent-accordionColumn-Qyfcq"
};