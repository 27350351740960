import React from 'react';
import Layout from '../../components/Layout';
import Failure from './failure';



export default function action({params}) {
  const title = 'fiserv failure';
  
      
      let bname = '';
      let cardnumber = '';
      let failreason = '';

      
      bname = params.bname;
      cardnumber = params.cardnumber
      failreason = params.failreason;
    
  return {
    title,
    component: 
    <Layout>
      <Failure
      title={title}
      bname={bname}
      cardnumber={cardnumber}
      failreason={failreason}
      />,
    </Layout>,
  };
}