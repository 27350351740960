import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset, change } from 'redux-form';
import { graphql, gql, compose } from 'react-apollo';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import {
  injectStripe,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements';
import CryptoJS from 'crypto-js';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// Component
import HouseRules from './HouseRules';
import Loader from '../../Loader';
import Link from '../../Link';
import Avatar from '../../Avatar';
import DriverInfo from './DriverInfo';
import ListExtras from './extrasSelector'

import messages from '../../../locale/messages';
import validate from './validate';
import imageOne from '/public/siteImages/showAllCars.svg';
import imageTwo from '/public/siteImages/stripImage.svg';
import imageFiserv from '/public/siteImages/fiserv.svg';
import showToaster from '../../../helpers/toasterMessages/showToaster';

import { makePayment } from '../../../actions/booking/makePayment'
import { makePreReservation } from '../../../actions/booking/makePreReservation'
import { processCardAction } from '../../../actions/PaymentIntent/processCardAction';
import { isRTL } from '../../../helpers/formatLocale'
import { COMMON_TEXT_COLOR } from '../../../constants/index';

import CurrencyConverter from '../../CurrencyConverter';
import { convert } from '../../../helpers/currencyConvertion';

import cs from '../../commonStyle.css';
import s from './Payment.css';
import es from './Es.css';
import { comment, list } from 'postcss';
import { set, values } from 'lodash';

// dolar rates
import dolarRatesAr  from '../../../helpers/exchangeRatesAR';
import calculateAverageMepBlue from '../../../helpers/calculateAverageMepBlue';
// enviroment variables
import { payment } from '../../../config';
import { payCash, paymentCalc } from './helperCalcPayment';

import {auth} from '../../../config';

import { getListExtras } from '../../../lib/graphql';



const createOptions = (isRTLLocale) => {
  return {
    style: {
      base: {
        color: COMMON_TEXT_COLOR,
        fontWeight: 400,
        fontFamily: 'inherit',
        textAlign: isRTLLocale ? 'right' : 'left',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':focus': {
          color: COMMON_TEXT_COLOR,
        },

        '::placeholder': {
          color: '#7D7D7D',
        },

        ':focus::placeholder': {
          color: '#7D7D7D',
        },
      },
      invalid: {
        color: '#303238',
        ':focus': {
          color: COMMON_TEXT_COLOR,
        },
        '::placeholder': {
          color: '#7D7D7D',
        },
      },
    }
  }
};


class PaymentForm extends Component {
  static propTypes = {
    houseRules: PropTypes.arrayOf(PropTypes.shape({
      listsettings: PropTypes.shape({
        itemName: PropTypes.string.isRequired
      })
    })),
    hostDisplayName: PropTypes.string.isRequired,
    allowedPersonCapacity: PropTypes.number.isRequired,
    initialValues: PropTypes.shape({
      listId: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      hostId: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      checkIn: PropTypes.object.isRequired,
      checkOut: PropTypes.object.isRequired,
      guests: PropTypes.number.isRequired,
      basePrice: PropTypes.number.isRequired,
      delivery: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      weeklyDiscount: PropTypes.number,
      monthlyDiscount: PropTypes.number,
      paymentType: PropTypes.number
    }).isRequired,
    paymentCurrencyList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      symbol: PropTypes.string.isRequired,
      isEnable: PropTypes.bool.isRequired,
      isPayment: PropTypes.bool.isRequired
    })),
    paymentLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    base: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    }),
    rates: PropTypes.object,
    toCurrency: PropTypes.string,
    guestServiceFee: PropTypes.number,
  };

  static defaultProps = {
    paymentCurrencyList: [],
    paymentLoading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: 2,
      load: true,
      amount: '',
      selectedCurrency: 'AR$', // Moneda seleccionada por defecto
      auth: auth,
      extrasTotal: 0,
      amountToPay: 0, // Inicialmente 0, se actualizará en componentDidMount
      reservationAmount: 0, // Inicialmente 0, se actualizará en componentDidMount
      extras: [],
      subTotal:0,
    }
  }
  
  async componentDidMount () {
   const listId = this.props.initialValues.listId;
    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: getListExtras,
        variables: { listId }
      }),
    });
    const { data } = await resp.json();
    this.setState({listExtras: data.getListExtras.results
    });
    this.updateAmounts();

  }

  
  componentDidUpdate(prevProps, prevState) {
    const { guestServiceFee, total } = this.props;
    const { extrasTotal, extras } = this.state;
  
    // Verifica si las props o el estado relevantes han cambiado
    if (prevProps.guestServiceFee !== guestServiceFee ||
        prevProps.total !== total ||
        prevState.extrasTotal !== extrasTotal ||
        prevState.extras !== extras  ) {  // Añadido comparación de extras
      this.updateAmounts();

    }
  
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;
  
    if (locale !== prevLocale) {
      this.setState({ load: false });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => this.setState({ load: true }), 1);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getAllPayments: { getPaymentMethods }, change } = nextProps;
    if (getPaymentMethods && getPaymentMethods.length == 1) {
      this.setState({
        paymentStatus: getPaymentMethods[0].id
      });
      change('paymentType', getPaymentMethods[0].id)
    }
  }

  renderFormControl = ({ input, label, type, placeholder, meta: { touched, error }, className, maxLength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx('inputFocusColorNone', cs.spaceBottom2)}>
        <FormControl {...input} placeholder={placeholder} type={type} className={className} maxLength={maxLength} />
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cs.spaceBottom3}>
        <FormControl disabled={disabled} componentClass="select" {...input} className={className} >
          {children}
        </FormControl>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cs.noMargin}>
        <FormControl
          {...input}
          className={className}
          componentClass="textarea"
          placeholder={label}
          rows={'6'}
        >
          {children}
        </FormControl>
        {touched && error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  renderGuests(personCapacity) {
    let rows = [];
    for (let i = 1; i <= personCapacity; i++) {
      rows.push(<option key={i} value={i}>{i} {i > 1 ? 'guests' : 'guest'}</option>);
    }
    return rows;
  }

  renderpaymentCurrencies = () => {
    const { paymentCurrencyList, paymentLoading } = this.props;
    let rows = [];

    if (paymentCurrencyList != null && paymentCurrencyList.length > 0) {
      paymentCurrencyList.map((item, index) => {
        if (item.isEnable && item.isPayment) {
          rows.push(<option key={index} value={item.symbol} disabled={paymentLoading}>{item.symbol}</option>);
        }
      })
    }
    return rows;
  }

  handleClick = () => {
    const { dispatch } = this.props;
    dispatch(reset('BookingForm'));
  }

  handleSubmit = async (values, dispatch, event) => {
    const { stripe, processCardAction, securityDepositStatus } = this.props;
    const {user} = this.props;
    const {auth} = this.state;
    const { amountToPay, reservationAmount, extras } = this.state;
    
    let extrasToBackend = []
    extras.map((item) => {
      extrasToBackend.push({
        listExtraId: item.id,
        name: item.name,
        price: item.price
      })
    });
    
    console.log('extrasToBackend', extrasToBackend);

   
    let paymentType = values.paymentType, paymentCurrency, month;
    let monthValue, dateValue, dateOfBirth;
    let today, birthDate, age, monthDifference, dobDate;
    let dateOfMonth = Number(values.month) + 1;

    dobDate = values.year + '/' + dateOfMonth + '/' + values.day
    paymentCurrency = values.paymentType == 1 ? values.paymentCurrency : null;
    month = values.month ? Number(values.month) + 1 : null;
    monthValue = Number(values.month) > 8 ? Number(month) : '0' + month;
    dateValue = values.day > 9 ? values.day : '0' + values.day;
    dateOfBirth = monthValue + "-" + dateValue + "-" + values.year;
    today = new Date();
    birthDate = new Date(dobDate);
    age = today.getFullYear() - birthDate.getFullYear();
    monthDifference = today.getMonth() - birthDate.getMonth();

    if (values.year) {
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) age--;
      if (age < 18) {
        showToaster({ messageId: 'checkAge', toasterType: 'error' })
        return false;
      }
    }

    let query = `query checkReservation ($checkIn: String,$checkOut: String,$listId: Int, $securityDepositStatus: String){
      checkReservation(checkIn: $checkIn, checkOut:$checkOut, listId:$listId, securityDepositStatus: $securityDepositStatus){
        id
        listId
        hostId
        guestId
        checkIn
        checkOut
        status
      }
    }`;

    const params = {
      listId: values.listId,
      checkIn: values.checkIn,
      checkOut: values.checkOut,
      securityDepositStatus: securityDepositStatus
    };

    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: params,
      }),
      credentials: 'include',
    });

    const { data } = await resp.json();

    if (data?.checkReservation && data?.checkReservation?.status == "200") {

      let msg = '', paymentMethodId, createPaymentMethod;


    /* fiserv integration */  

    if (paymentType == 2) {

    //event.preventDefault();

    const currency  = this.props.currency;
    //amount = parseFloat(this.props.total) + parseFloat(this.props.guestServiceFee);
  
    const reponse = await dispatch(makePreReservation(
      values?.listId,
      values?.listTitle,
      values?.hostId,
      values?.guestId,
      values?.checkIn,
      values?.checkOut,
      values?.guests,
      values?.message,
      values?.basePrice,
      values?.delivery,
      values?.currency,
      values?.discount,
      values?.discountType,
      values?.guestServiceFee,
      values?.hostServiceFee,
      values?.total,
      values?.bookingType,
      paymentCurrency,
      paymentType,
      values?.guestEmail,
      values?.bookingSpecialPricing,
      values?.isSpecialPriceAssigned,
      values?.isSpecialPriceAverage,
      values?.dayDifference,
      values?.startTime,
      values?.endTime,
      values?.licenseNumber,
      values?.firstName,
      values?.middleName,
      values?.lastName,
      dateOfBirth,
      values?.country,
      paymentMethodId,
      values?.securityDeposit,
      values?.hostSeriveFeeType,
      values?.hostSeriveFeeValue,
      extrasToBackend,
      reservationAmount,
      amountToPay
    )
    );

   
    if (data == false) {
      showToaster({ messageId: 'invalidError', toasterType: 'error', requestMessage: `Error al crear la pre-reserva` })
      return;
    }

  

    
    const valuesFiserv = {
      txntype: payment.fiserv.txntype,
      timezone: payment.fiserv.time_zone,
      txndatetime: this.getDateTime(),
      hash_algorithm: payment.fiserv.hash_algorithm,
      storename: payment.fiserv.storename,
      currency: '032',
      chargetotal: reservationAmount,
      responseFailURL: payment.fiserv.response_fee_fail_url,
      responseSuccessURL: payment.fiserv.response_fee_success_url,
      checkoutoption: payment.fiserv.checkout_option,
      customParam_preReservationId: reponse.preReservationDetails.preReservationId,
      customParam_userId: user.id,
      customParam_userEmail: user.email,
      assignToken: true
    };



    const hashExtended = this.createExtendedHash(valuesFiserv, payment.fiserv.shared_secret);

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = payment.fiserv.gateway_url;
    
    Object.keys(valuesFiserv).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = valuesFiserv[key];
      form.appendChild(input);
    });

    const hashInput = document.createElement('input');
    hashInput.type = 'hidden';
    hashInput.name = 'hashExtended';
    hashInput.value = hashExtended;
    form.appendChild(hashInput);

    document.body.appendChild(form);
    form.submit();
    return;

    



      // if (paymentType == 2) {
      //   createPaymentMethod = await stripe.createPaymentMethod('card', {
      //     card: <CardElement />,
      //     billing_details: {
      //       address: {
      //         postal_code: values.zipcode
      //       }
      //     }
      //   })

        // if (createPaymentMethod?.paymentMethod) {
        //   paymentMethodId = createPaymentMethod.paymentMethod.id
        // }
      }

      if (createPaymentMethod?.error && createPaymentMethod?.error?.message && paymentType == 2) {
        msg = createPaymentMethod.error.message
        showToaster({ messageId: 'invalidError', toasterType: 'error', requestMessage: msg })
      } else {

        if (Number(values.paymentType) == 2 && !values.zipcode) {
          showToaster({ messageId: 'inCompleteZipCode', toasterType: 'error' })
          return;
        }

        const { status, paymentIntentSecret, reservationId } = await dispatch(makePayment(
          values?.listId,
          values?.listTitle,
          values?.hostId,
          values?.guestId,
          values?.checkIn,
          values?.checkOut,
          values?.guests,
          values?.message,
          values?.basePrice,
          values?.delivery,
          values?.currency,
          values?.discount,
          values?.discountType,
          values?.guestServiceFee,
          values?.hostServiceFee,
          values?.total,
          values?.bookingType,
          paymentCurrency,
          paymentType,
          values?.guestEmail,
          values?.bookingSpecialPricing,
          values?.isSpecialPriceAssigned,
          values?.isSpecialPriceAverage,
          values?.dayDifference,
          values?.startTime,
          values?.endTime,
          values?.licenseNumber,
          values?.firstName,
          values?.middleName,
          values?.lastName,
          dateOfBirth,
          values?.country,
          paymentMethodId,
          values?.securityDeposit,
          values?.hostSeriveFeeType,
          values?.hostSeriveFeeValue
        )
        );

        if (status == 400 && paymentType == 2) {
          const cardAction = await stripe.handleCardAction(
            paymentIntentSecret,
          );
          let amount = values?.total + values?.guestServiceFee, confirmPaymentIntentId;

          if (cardAction?.paymentIntent && cardAction?.paymentIntent?.id) {
            confirmPaymentIntentId = cardAction?.paymentIntent?.id;
            await processCardAction(
              reservationId,
              values?.listId,
              values?.hostId,
              values?.guestId,
              values?.listTitle,
              values?.guestEmail,
              amount,
              values?.currency,
              confirmPaymentIntentId
            );
          } else {
            if (cardAction?.error && cardAction?.error?.message) {
              msg = cardAction.error.message;
              showToaster({ messageId: 'invalidError', toasterType: 'error', requestMessage: msg })
            }
          }
        }
      }
    } else if (data?.checkReservation?.status == "404") {
      showToaster({ messageId: 'securityDepositChange', toasterType: 'error' })
      return;
    } else {
      showToaster({ messageId: 'datesNotAvailable', toasterType: 'error' })
    }
  }

  handlePayment = (e) => {
    let paymentType = e.target.value;

    if (paymentType == 2) {
      this.setState({ paymentStatus: 2 })
    } else {
      this.setState({ paymentStatus: 1 })
    }

  }

  formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  formatNumberN = (num) => {
    num = num.toString();
    num.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return num;
  }
  
  handleAmountChange = (e) => {
    const inputValue = e.target.value.replace(/\./g, '');
    if (!isNaN(inputValue)) {
      const formattedValue = this.formatNumber(inputValue);
      setValue(formattedValue);
    }
    this.setState({ amount: e.target.value });
  };



  createExtendedHash = (values, sharedSecret) => {
    const sortedKeys = Object.keys(values).sort();
    const hashString = sortedKeys.map(key => values[key]).join('|');
    const hash = CryptoJS.HmacSHA256(hashString, payment.fiserv.shared_secret);
    return CryptoJS.enc.Base64.stringify(hash);
  };

  getDateTime = () => {
    const now = new Date();
    const pad = (n) => (n < 10 ? '0' : '') + n;
    return `${now.getFullYear()}:${pad(now.getMonth() + 1)}:${pad(now.getDate())}-${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`;
  };


  handleExtraTotalChange = (nuevoTotal, itemsSeleccionados) => {
    this.setState({
      extrasTotal: nuevoTotal,  // Asegúrate de usar el nombre correcto del parámetro
      extras: itemsSeleccionados
    });
  };

handleChangeExtrasValue = (e) => {  
  this.setState({ extrasTotal: e.target.value });
};

updateAmounts = () => {
  let { guestServiceFee, total } = this.props;
  const { extrasTotal, subTotal } = this.state;
  const { base, rates, currency, toCurrency } = this.props;

  if (currency != 'ARS') {
    total = convert(base, rates, total, currency, 'ARS');
    guestServiceFee = convert(base, rates, guestServiceFee, currency, 'ARS');
  }

  function sumSubTotal ( total , guestServiceFee) {
    return total + guestServiceFee;
  }

  this.setState({
    amountToPay: parseFloat(payCash( guestServiceFee || 0, ((total || 0) + extrasTotal))),
    reservationAmount: parseFloat(paymentCalc( guestServiceFee, (( total || 0) + extrasTotal))),
    subTotal: sumSubTotal(total, guestServiceFee)
  });

}


  render() {
    const { hostDisplayName, houseRules, hostPicture, paymentLoading, intl: { locale }, getAllPayments: { getPaymentMethods } } = this.props;
    const { handleSubmit, submitting, error, paymentType, hostProfileId, hostJoined, listId } = this.props;
    const { paymentStatus, load, listExtras, extrasTotal, amountToPay, reservationAmount} = this.state;
    const { formatMessage } = this.props.intl;
    const { amount, selectedCurrency, auth, precioDolarFinal } = this.state;
    const { user, guestServiceFee, total, currency, securityDeposit } = this.props;
    const {base, toCurrency, rates} = this.props;
    const {subTotal} = this.state;
    

    let joinedDate = hostJoined != null ? moment(hostJoined).format("MMM, YYYY") : '';

    return( 
      currency && base && rates && guestServiceFee !== undefined ? (<div className={cx('inputFocusColor')}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <h1 className={cx(cs.commonTitleText, cs.paddingBottom4)}><FormattedMessage {...messages.reviewandPay} /></h1>
          <h3 className={cx(s.titleText, cs.paddingBottom2)}>
            1.{' '}<FormattedMessage {...messages.liscenseInfo} />
          </h3>
          <h4 className={cx(cs.commonContentText, cs.paddingBottom4)}>
            <FormattedMessage {...messages.aboutLiscenseContent} />
          </h4>
          <DriverInfo />
          <hr className={s.horizondalLine} />
          <h5 className={cx(s.titleText, cs.paddingBottom2)}>
            2.{' '}<FormattedMessage {...messages.aboutYourTrip} />
          </h5>
          <h5 className={cx(cs.commonContentText, cs.paddingBottom4)}>
            <FormattedMessage {...messages.sayHello} />
          </h5>
          <div className={cx(s.avatarImageGrid, cs.spaceBottom4)}>
            <Avatar
              source={hostPicture}
              type={"small"}
              height={80}
              width={80}
              title={hostDisplayName}
              className={cx(cs.profileAvatarLink, cs.profileAvatarLinkPayment)}
              withLink
              linkClassName={cs.displayinlineBlock}
              profileId={hostProfileId}
            />
            <div className={cx(s.textSection, 'viewListingTextSectionRTL')}>
              <a href={"/users/show/" + hostProfileId} target={'_blank'} className={cx(cs.commonSubTitleText, cs.siteTextColor, cs.fontWeightBold)}>
                {formatMessage(messages.hostedBy)} {' '}  <span className={cs.siteLinkColor}>{hostDisplayName}</span>
              </a>
              <h4 className={cx(cs.commonContentText, cs.fontWeightNormal, cs.paddingTop1)}>
                {formatMessage(messages.joinedIn)}{' '}{joinedDate}
              </h4>
            </div>
          </div>
          <Field
            name="message"
            component={this.renderFormControlTextArea}
            label={formatMessage(messages.descriptionInfo)}
            className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
          />
          <hr className={s.horizondalLine} />
          {
            houseRules?.length > 0 && <>
              <HouseRules
                hostDisplayName={hostDisplayName}
                houseRules={houseRules}
              />
              <hr className={s.horizondalLine} />
            </>
          }
          
          {  listExtras && listExtras.length > 0 && 
          <div>
          <ListExtras 
            listado={listExtras}
            onTotalChange={this.handleExtraTotalChange}
          />
          <hr className={s.horizondalLine} />
          <div className={cx(es.extraSection, es.container)}>
            
            <p className={cx(es.subtotal)}> 
              Sub total alquiler sin extras 
              { guestServiceFee !== undefined ? (<span className={cx(es.spanSubtotal)}>
                {toCurrency} { 
                  subTotal.toFixed(2)
                }
              </span>) : (<span className={cx(es.spanSubtotal)}>Cargando...</span>)}
            </p>
            
            <p className={cx(es.subtotal)}> 
              Sub total extras 
              <span className={cx(es.spanSubtotal)}> 
                {toCurrency} {extrasTotal.toFixed(2)}
              </span>
            </p>
            
            <p className={cx(es.total)}> 
              Total a pagar 
              <span className={cx(es.spanTotal)}>
                {toCurrency} {
                  (subTotal + extrasTotal).toFixed(2)
                }
              </span>
            </p>
          
          </div>
          <hr className={s.horizondalLine} />
        </div>
          }
          <div>
            <h5 className={cx(s.titleText, cs.paddingBottom2)}>4.{' '}<FormattedMessage {...messages.payment} /></h5>
            {getPaymentMethods?.length > 1 &&
              <>
                <h6 className={cx(cs.commonContentText, cs.paddingBottom4)}><FormattedMessage {...messages.paymentText} /></h6>
                <label><FormattedMessage {...messages.PaymentmethodText} /></label>
                <Field
                  name="paymentType"
                  type="text"
                  className={cs.formControlSelect}
                  component={this.renderFormControlSelect}
                  onChange={(e) => this.handlePayment(e)}
                >
                  <option value={2}>{formatMessage(messages.creditCard)}</option>
                  <option value={1}>{formatMessage(messages.payPal)}</option>
                </Field>
              </>
            }
            {
              getPaymentMethods?.length == 1 && <>
                <label className='textAlignRightRTL'>{paymentStatus == 2 ? formatMessage(messages.stripeContent) : formatMessage(messages.paypal)}</label>
              </>
            }
            {
              paymentStatus == 2 ? (!load ? <Loader /> :
                <div>
                      <form onSubmit={this.handleSubmit}>
                      
                        <div>
                        {currency && base && rates && reservationAmount ? (
                            <>
                              <label>Vas a abonar en concepto de reserva la cantidad de {toCurrency}</label>
                              <input
                                type="text"
                                value={reservationAmount}
                                placeholder={reservationAmount}
                                className={cs.formControlSelect}
                                disabled={true}
                                style={{ fontSize: '20px', background: '#ffe4c4' }}
                              />
                            </>
                          ) : (
                            <p>Cargando...</p> // Puedes mostrar un mensaje de carga o un indicador
                          )}
                        </div>
                        <div>
                        {currency && base && rates && amountToPay ? (
                            <>
                              <label>Vas a abonar en concepto de reserva la cantidad de {toCurrency}</label>
                              <input
                                type="text"
                                value={amountToPay}
                                placeholder={amountToPay}
                                className={cs.formControlSelect}
                                disabled={true}
                                style={{ fontSize: '20px', background: '#ffe4c4' }}
                              />
                            </>
                          ) : (
                            <p>Cargando...</p> // Puedes mostrar un mensaje de carga o un indicador
                          )}
                        </div>
                        <div>


                        <p style={
                            {fontSize: '14px', color: 'gray', marginTop: '20px'}
                          }>Para más información sobre el depósito de garantía <a href="/securitydeposit" target='_blank'>click aqui.</a></p>
                        </div>
                      
                        <div
                        style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px', flexDirection: 'row'}}
                        >
                            <div>
                              <img 
                              src={imageOne} 
                              alt="card imnages"
                              style={{width: '120px', height: '120px'}}
                              />
                            </div>
                            <div>
                              <img 
                              src={imageFiserv} 
                              alt="fiserv logo"
                              style={{width: '80px', height: '80px'}}
                              />
                            </div>
                        </div>
                        
                      </form>

                </div>
              ) : <span></span>
            }
            {
              paymentStatus == 1 &&
              <>
                <Field name="paymentCurrency" disabled={paymentType == 2} 
                component={this.renderFormControlSelect} 
                className={cs.formControlSelect} >
                  <option 
                  disabled={paymentLoading} 
                  value="">{formatMessage(messages.chooseCurrency)}</option>
                  {
                    this.renderpaymentCurrencies()
                  }
                </Field>
                <p className={cx(cs.commonContentText, cs.paddingBottom4)}>
                  <FormattedMessage {...messages.loginInfo} />
                </p>
              </>
            }
            <div className={s.payNowFlex}>
              <div className={cx(s.cancelBtn, 'RTLcancelBtn')}>
                {
                  !paymentLoading && <>
                    <Link
                      to={"/cars/" + listId}
                      className={cx(cs.btnPrimaryBorder, cs.displayinlineBlock, cs.spaceTop5)}
                      onClick={this.handleClick}
                    >
                      <FormattedMessage {...messages.cancel} />
                    </Link>
                  </>
                }
                {
                  paymentLoading && <>
                    <a
                      href="javascript:void(0)"
                      className={cx(cs.btnPrimaryBorder, cs.displayinlineBlock, cs.spaceTop5, s.cancelLinkText, {
                        [s.disabledLink]: submitting == true,
                      })}
                    >
                      <FormattedMessage {...messages.cancel} />
                    </a>
                  </>
                }
              </div>
              <Loader
                type={"button"}
                buttonType={"submit"}
                className={cx(cs.btnPrimary, cs.spaceTop5, 'arButtonLoader')}
                disabled={submitting || error}
                show={paymentLoading}
                label={formatMessage(messages.payNow)}
              />
            </div>
          </div>
        </form>
      </div>):(
        <div>
          <p>Cargando...</p>
        </div>
      )
    );
  }


}

PaymentForm = reduxForm({
  form: 'PaymentForm',
  validate
})(PaymentForm);

const selector = formValueSelector('PaymentForm');


// const mapStateToProps = (state) => ({
//   user: state.user,
//   paymentMethods: state.paymentMethods,
//   // Agrega más estados según sea necesario
// });

const mapState = (state) => ({
  paymentCurrencyList: state?.currency?.availableCurrencies,
  paymentLoading: state?.book?.paymentLoading,
  paymentType: selector(state, 'paymentType'),
  securityDepositStatus: state?.siteSettings?.data?.securityDepositPreference,
  user: state?.user,
  paymentMethods: state?.paymentMethods,
  token: state.token,
  total: state.form.PaymentForm?.values.total,
  guestServiceFee: state.form.PaymentForm?.values.guestServiceFee, 
  currency: state.form.PaymentForm?.values.currency,
  securityDeposit: state.form.PaymentForm?.values.securityDeposit,
  base: state.currency.base,
  toCurrency: state.currency.to,
  rates: state.currency.rates,
  
});

const mapDispatch = {
  processCardAction,
  change
};

export default injectStripe(compose(
  injectIntl,
  withStyles(s, cs),
  (connect(mapState, mapDispatch)),
  graphql(gql`
    query getCountries {
        getCountries{
            id
            countryCode
            countryName
            isEnable
            dialCode
        }
    }
`, { options: { ssr: false } }),
  graphql(gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      name
      isEnable
      paymentType
      paymentName
      status
    }
  }
  `,
    { name: 'getAllPayments' },
    { options: { ssr: false } }
  )
)(PaymentForm))