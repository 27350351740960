

const calculateAverageMepBlue = (mapRates) => {
    let mep;
    let blue;
    mapRates.forEach((rate) => {
        if (rate.casa === 'blue') {
            blue = rate.venta;
        }
        if (rate.casa === 'bolsa') {
            mep = rate.venta;
        }
    }
    );
    return (mep + blue) / 2;
}; 

export default calculateAverageMepBlue;