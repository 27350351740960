import moment from 'moment';
import showToaster from '../../helpers/toasterMessages/showToaster';
import { sendPayment } from '../../core/payment/sendPayment';
import { convert } from '../../helpers/currencyConvertion';
import { processStripePayment } from '../../core/payment/stripe/processStripePayment';
import { startTimeData } from '../../helpers/formatting';
import { createPreReservation as mutation } from '../../lib/graphql';
import {
  BOOKING_PAYMENT_START,
  BOOKING_PAYMENT_SUCCESS,
  BOOKING_PAYMENT_ERROR,
} from '../../constants';

export const makePreReservation = (
  listId,
  title,
  hostId,
  guestId,
  checkIn,
  checkOut,
  guests,
  message,
  basePrice,
  delivery,
  currency,
  discount,
  discountType,
  guestServiceFee,
  hostServiceFee,
  total,
  bookingType,
  paymentCurrency,
  paymentType,
  guestEmail,
  specialPricing,
  isSpecialPriceAssigned,
  isSpecialPriceAverage,
  dayDifference,
  startTime,
  endTime,
  licenseNumber,
  firstName,
  middleName,
  lastName,
  dateOfBirth,
  countryCode,
  paymentMethodId,
  securityDeposit,
  hostServiceFeeType,
  hostServiceFeeValue,
  extras,
  reservationAmount,
  amountToPay
) => {

  return async (dispatch, getState, { client }) => {
    try {

      // dispatch({
      //   type: BOOKING_PAYMENT_START,
      //   payload: {
      //     paymentLoading: true
      //   }
      // });
      
      let preApprove, bookingTypeData, cancellationPolicy, isStartValue, isStartDate, checkInDate, checkOutDate, isEndDate, isEndValue;
      let securityDepositStatus, securityDepositAmount;
      preApprove = getState().book?.bookDetails?.preApprove;
      bookingTypeData = preApprove ? 'instant' : bookingType;
      cancellationPolicy = getState().book?.data?.listingData?.cancellation?.id;
      isStartValue = startTimeData(startTime), isEndValue = startTimeData(endTime);
      isStartDate = moment(checkIn).format('YYYY-MM-DD'), isEndDate = moment(checkOut).format('YYYY-MM-DD');
      checkInDate = moment.utc(isStartDate + ' ' + isStartValue), checkOutDate = moment.utc(isEndDate + ' ' + isEndValue);
      securityDepositStatus = getState().siteSettings?.data?.securityDepositPreference;
      securityDepositAmount = securityDepositStatus == 1 ? securityDeposit : 0

      const coso1 = parseFloat(reservationAmount);
      const coso2 = parseFloat(amountToPay);
 
      const { data } = await client.mutate({
        mutation,
        variables: {
          listId,
          hostId,
          guestId,
          checkIn: checkInDate,
          checkOut: checkOutDate,
          guests,
          message,
          basePrice,
          delivery,
          currency,
          discount,
          discountType,
          guestServiceFee,
          hostServiceFee,
          total,
          bookingType: bookingTypeData,
          paymentType,
          cancellationPolicy,
          specialPricing,
          isSpecialPriceAssigned,
          isSpecialPriceAverage,
          dayDifference,
          startTime,
          endTime,
          licenseNumber,
          firstName,
          middleName,
          lastName,
          dateOfBirth,
          countryCode,
          securityDeposit: securityDepositAmount,
          hostServiceFeeType,
          hostServiceFeeValue,
          extras,
          reservationAmount: coso1,
          amountToPay: coso2
        }
      })
      console.log('terminamos de crear la pre-reserva');


      if (data?.createPreReservation) {
        let preReservationId, amount, rates, currentCurrency, baseCurrency, convertedAmount = 0, preReservationDetails;
        preReservationId = data?.createPreReservation?.id;
        amount = total + guestServiceFee + securityDepositAmount;

        rates = getState().currency?.rates;
        currentCurrency = (getState().currency?.to) ? getState().currency?.to : getState().currency?.base;
        baseCurrency = getState().currency?.base;

        preReservationDetails = {
          preReservationId,
          listId,
          hostId,
          guestId,
          guestEmail,
          title,
          amount: convertedAmount.toFixed(2),
          currency: currentCurrency
        };
        return {
          status: true,
          preReservationDetails
        };
      }
    } catch (error) {
      console.log('error makePrereservation----->', error);
      // dispatch({
      //   type: BOOKING_PAYMENT_ERROR,
      //   payload: {
      //     error,
      //     paymentLoading: false
      //   }
      // });
      return error;
    }

    return true;
  };
}