import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AddCarExtras.css';
import bt from '../../components/commonStyle.css';

import FooterButton from '../ListPlaceStep1/FooterButton';

// Components
import Link from '../../components/Link';

// Locale
import messages from '../../locale/messages';

class AddCarExtrasComponent extends React.Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      extras: props.extras,
      showModal: false,
      editItemId: null,
      newName: '',
      newPrice: '',
      nextId: 3,
      extras: [],
    };
  }



  componentDidMount() {
    const query = `
  query GetListExtras($listId: Int!) {
    getListExtras(listId: $listId) {
      status
      errorMessage
      count
      results {
        id
        listId
        name
        price
      }
    }
  }
`;


fetch(backendUrl, {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    query: query,
    variables: variables
  }),
})
.then(res => res.json()).catch((error) => { console.log(error); 
  });
}

  handleShow = (item = null) => {
    if (item) {
      this.setState({ 
        showModal: true, 
        editItemId: item.id, 
        newName: item.name, 
        newPrice: item.price.toString() 
      });
    } else {
      this.setState({ 
        showModal: true, 
        editItemId: null, 
        newName: '', 
        newPrice: '' 
      });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false, editItemId: null, newName: '', newPrice: '' });
  };

  handleNameChange = (e) => this.setState({ newName: e.target.value });
  handlePriceChange = (e) => this.setState({ newPrice: e.target.value });

  handleSave = () => {
    const { extras, editItemId, newName, newPrice, nextId } = this.state;
    if (newName && newPrice) {
      if (editItemId) {
        // Update existing item
        this.setState({
          extras: extras.map(extra => 
            extra.id === editItemId 
              ? { ...extra, name: newName, price: parseFloat(newPrice) } 
              : extra
          ),
          showModal: false,
          editItemId: null,
          newName: '',
          newPrice: '',
        });
      } else {
        // Add new item
        this.setState({
          extras: [...extras, { id: nextId, name: newName, price: parseFloat(newPrice) }],
          showModal: false,
          newName: '',
          newPrice: '',
          nextId: nextId + 1,
        });
      }
    }
  };

  handleDelete = (id) => {
    this.setState(prevState => ({
      extras: prevState.extras.filter(extra => extra.id !== id)
    }));
  };

  render() {
    const { nextPage, previousPage } = this.props;
    
    const { extras, showModal, editItemId, newName, newPrice } = this.state;
    
    return (
      <div className={s.container}>
        <div className={s.gridFluid}>
          <div className={cx(s.space8, s.spaceTop8)}>
            <div className={bt.textAlignCenter}>
              <h2>Car Extras</h2>
              <div className={s.extrasList}>
                {extras.map((extra) => (
                  <div key={extra.id} className={s.extraItem}>
                    <span className={s.extraName}>{extra.name}</span>
                    <span className={s.extraPrice}>${extra.price.toFixed(2)}</span>
                    <button 
                      onClick={() => this.handleShow(extra)}
                      className={cx(bt.btnPrimaryBorder, s.editButton)}
                    >
                      Edit
                    </button>
                    <button 
                      onClick={() => this.handleDelete(extra.id)}
                      className={cx(bt.btnPrimary, s.deleteButton)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
              <button onClick={() => this.handleShow()} className={cx(bt.btnPrimary, s.addButton)}>
                Add New Extra
              </button>

              {showModal && (
                <div className={s.modal}>
                  <div className={s.modalContent}>
                    <h3>{editItemId ? 'Edit Extra' : 'Add New Extra'}</h3>
                    <form>
                      <div className={bt.formGroup}>
                        <label>Name</label>
                        <input 
                          type="text" 
                          value={newName} 
                          onChange={this.handleNameChange} 
                          className={bt.formControlInput}
                        />
                      </div>
                      <div className={bt.formGroup}>
                        <label>Price</label>
                        <input 
                          type="number" 
                          value={newPrice} 
                          onChange={this.handlePriceChange} 
                          className={bt.formControlInput}
                        />
                      </div>
                    </form>
                    <div>
                      <button onClick={this.handleClose} className={bt.btnPrimaryBorder}>Cancel</button>
                      <button onClick={this.handleSave} className={bt.btnPrimary}>{editItemId ? 'Update' : 'Add'}</button>
                    </div>

                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default injectIntl(withStyles(s, bt)(connect(AddCarExtrasComponent)));
