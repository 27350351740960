import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Layout4.css';
import bt from '../../commonStyle.css';
import cx from 'classnames';
import SearchForm from '../SearchForm/SearchForm';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

class Layout4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInstallable: false,
      deferredPrompt: null,
      isVisible: true,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', this.handleAppInstalled);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.removeEventListener('appinstalled', this.handleAppInstalled);
  }

  handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    this.setState({
      deferredPrompt: e,
      isInstallable: true,
      isVisible: true,
    });
  };

  handleAppInstalled = () => {
    this.setState({
      deferredPrompt: null,
      isInstallable: false,
      isVisible: false,
    });
  };

  handleInstallClick = async () => {
    const { deferredPrompt } = this.state;
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      if (outcome === 'accepted') {
        this.setState({
          isInstallable: false,
          deferredPrompt: null,
          isVisible: false,
        });
      }
    }
  };

  render() {
    const { title, content, bannerImage } = this.props;
    const { isInstallable, isVisible } = this.state;

    return (
      <div className={s.bgCss} style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className={s.sectionWidth}>

          {isInstallable && isVisible && (
            <div className={s.installb}>
              <button
                onClick={this.handleInstallClick}
                className={s.installButtonStyle}
                style={{ marginBottom: '10px' }} // Cambiado para separación vertical
              >
                <FormattedMessage {...messages.installApplication} />
              </button>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white" // Fondo blanco
                viewBox="0 0 30 30"
                stroke="currentColor"
                strokeWidth={2}
                className={s.arrowDown}
              >
                <circle cx="15" cy="15" r="12" stroke="currentColor" strokeWidth="2" />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10l0 8m0 0l-4-4m4 4l4-4"  // Modifiqué ligeramente el path
                  //M16 12l-4 4m0 0l-4-4m4 4V8
                  strokeWidth="2" // Líneas más gruesas
                />
              </svg>
            </div>
          )}

          <SearchForm />
          <div>
            <h1 className={cx(s.bannerCaptionText, bt.paddingTop5)}>
              {title}
            </h1>
            <div>
              <h1 className={cx(s.subBannerCaptionText, bt.paddingTop5)}>
               {content}
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s, bt)(Layout4));
