

export const totalExtrasAmount = (extras) => {
    let total = 0;
    extras.forEach((extra) => {
        total += extra.price;
    });
    return total;
    };

export const reservationExtrasAmount = (extras) => {
    let total = 0;
    extras.forEach((extra) => {
        total += extra.price;
    });
    return (total) * 0.2;
    }
