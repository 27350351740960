import { createLogger as reduxLogger } from 'redux-logger';

export default function createLogger() {
  if (process.env.NODE_ENV !== '_DEVELOPMENT_') {
    // Si no estás en desarrollo, devuelve una función vacía que no haga nada
    return () => next => action => next(action);
  }

  return reduxLogger({
    collapsed: true,
  });
}