import React from 'react';
import Layout from '../../components/Layout';
import AddCarExtras from './AddCarExtras';
import NotFound from '../notFound/NotFound';
import fetch from '../../core/fetch';
import { getListExtras } from '../../lib/graphql/listing'
import makeGraphQLRequest  from '../../helpers/apiHelpers';
import {graphql} from 'react-apollo';
import extractListData from './extractListData';
import ErrorPage from '../error/ErrorPage';

const title = 'Add Car Extras';

function renderNotFound() {
  return {
    title,
    component: <Layout><NotFound title={title} /></Layout>,
    status: 404,
  };
}



export default async function action({ store, params }) {

  const listId = Number.parseInt(params.listId);
  

  // From Redux Store
  const isAuthenticated = store.getState().runtime.isAuthenticated;
  const isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
  let privileges = store.getState().listSettings && store.getState().listSettings.privileges;
  const loguedInUser = store.getState().user;
  //console.log('loguedInUser', loguedInUser);

  const listUserId = await extractListData(listId); 
  
  if (listUserId != loguedInUser.id) {
   return {redirect: '/dashboard'};
  }

  
  if (!isAuthenticated && !isAdminAuthenticated) {
    return { redirect: '/login' };
  }

  // Admin restriction
  if (isAdminAuthenticated && !restrictUrls('/become-a-owner/', adminPrivileges, privileges)) {
    return { redirect: '/siteadmin' };
  }
  
  if (listId === undefined || isNaN(listId)) {
    renderNotFound();
    return;
  }
  
  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: getListExtras,
      variables: { listId }
    }),
  });
  const { data } = await resp.json();
  
  let extras = [];
  
  if (resp.errors) {
    
    // Handle the error appropriately (e.g., show an error message to the user)
  } else if (data && data.getListExtras) {
    extras = data.getListExtras.results;
  
  } else {
    console.error('Unexpected response structure:', resp);
    
  }

  return {
    title,
    component: 
    <Layout>
      <AddCarExtras title={title} extras={extras} listId={listId}/>,
    </Layout>,
    status: 200,
  };
}

