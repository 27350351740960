import React from 'react';
import PropTypes, { array } from 'prop-types';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import EmailSignature from './EmailSignature';
import { urlMail } from '../../../config';
import { COMMON_TEXT_COLOR } from '../../../constants/index';

class InsuranceContactEmail extends React.Component {

    static propTypes = {
        content: PropTypes.shape({
            siteName: PropTypes.string.isRequired,
            phoneNumber: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            contactMessage: PropTypes.string,
            make: PropTypes.string.isRequired,
            model: PropTypes.string.isRequired,
            carUsage: PropTypes.string.isRequired,
            year: PropTypes.number.isRequired,
            origin: PropTypes.string.isRequired,
            genericType: PropTypes.string.isRequired,
            carCategoryVariant: PropTypes.string.isRequired,
            numberPlate: PropTypes.string.isRequired,
            vin: PropTypes.string.isRequired,
            vehicleValue: PropTypes.number.isRequired,
            coverage: PropTypes.string.isRequired,
            gps: PropTypes.string.isRequired,
            accesorios: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                })
            ),
            adicionales: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                })
            ),
        })
    };

    render() {
        const textStyle = {
            color: COMMON_TEXT_COLOR,
            backgroundColor: '#F7F7F7',
            fontFamily: 'Arial',
            fontSize: '16px',
            padding: '35px'
        };
        const { content: { contactMessage, email, name, lastName, phoneNumber, logo, siteName, make, model, carUsage, year, origin, genericType, carCategoryVariant, numberPlate, vin, vehicleValue, coverage, gps, accesorios, adicionales } } = this.props;
        console.log('accesorios en el mail', accesorios);
        console.log('adicionales en el mail', adicionales);
        return (
            <Layout>
                <Header
                    color="rgb(255, 90, 95)"
                    backgroundColor="#F7F7F7"
                    logo={logo}
                    siteName={siteName}
                />
                <Body textStyle={textStyle}>
                    <div>
                        Hola administrador,
                    </div>
                    <EmptySpace height={20} />
                    <div>
                        cotizacion de seguro para {name} {lastName} 
                        <br/>
                        recibida el dia {new Date().toLocaleDateString()}

                    </div>
                    <EmptySpace height={20} />
                    <div>
                        <strong>Nombre:</strong> {name}<br /><br />
                        <strong>Apellido:</strong> {lastName}<br /><br />
                        <strong>Correo electrónico:</strong> {email}<br /><br />
                        <strong>Número de contacto:</strong> {phoneNumber}<br /><br />

                        {/* Mensaje opcional */}
                        {contactMessage && (
                            <>
                                <strong>Mensaje:</strong> {contactMessage}<br /><br />
                            </>
                        )}

                        {/* Datos del vehículo */}
                        <strong>Marca:</strong> {make}<br /><br />
                        <strong>Modelo:</strong> {model}<br /><br />
                        <strong>Uso del vehículo:</strong> {carUsage}<br /><br />
                        <strong>Año:</strong> {year}<br /><br />
                        <strong>Origen:</strong> {origin}<br /><br />
                        <strong>Tipo Genérico:</strong> {genericType}<br /><br />
                        <strong>Variante de categoría del vehículo:</strong> {carCategoryVariant}<br /><br />
                        <strong>Número de placa:</strong> {numberPlate}<br /><br />
                        <strong>VIN:</strong> {vin}<br /><br />

                        {/* Campos opcionales */}
                        {vehicleValue && (
                            <>
                                <strong>Valor del vehículo:</strong> {vehicleValue}<br /><br />
                            </>
                        )}
                        <strong>Cobertura:</strong> {coverage}<br /><br />
                        <strong>GPS:</strong> {gps}<br /><br />

                        {/* Accesorios opcionales */}
                        {accesorios && accesorios.length > 0 && (
                            <>
                                <strong>Accesorios:</strong><br />
                                <ul>
                                    {accesorios.map((item, index) => (
                                        <li key={index}>{item.name} - {item.value}</li>
                                    ))}
                                </ul>
                                <br />
                            </>
                        )}

                        {/* Adicionales opcionales */}
                        {adicionales && adicionales.length > 0 && (
                            <>
                                <strong>Adicionales:</strong><br />
                                <ul>
                                    {adicionales.map((item, index) => (
                                        <li key={index}>{item.name} - {item.value}</li>
                                    ))}
                                </ul>
                                <br />
                            </>
                        )}
                    </div>
                    <EmptySpace height={30} />
                    <EmailSignature siteName={siteName} />
                </Body>
                <Footer siteName={siteName} />
                <EmptySpace height={20} />
            </Layout>
        );

    }

}

export default InsuranceContactEmail;