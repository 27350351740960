exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WhatsAppIcon-whatsappContainer-3BIEG {\n    position: fixed;\n    bottom: 75px; /* Ajusta la distancia desde la parte inferior */\n    right: 30px; /* Ajusta la distancia desde la parte derecha */\n    z-index: 1000; /* Coloca el ícono por encima de otros elementos */\n}\n\n.WhatsAppIcon-whatsappIcon-2fEFd {\n    width: 70px; /* Ajusta el tamaño del ícono */\n    height: 70px;\n    cursor: pointer;\n    -webkit-transition: -webkit-transform 0.3s ease;\n    transition: -webkit-transform 0.3s ease;\n    -o-transition: transform 0.3s ease;\n    transition: transform 0.3s ease;\n    transition: transform 0.3s ease, -webkit-transform 0.3s ease; /* Animación al pasar el ratón */\n}\n\n.WhatsAppIcon-whatsappIcon-2fEFd:hover {\n    -webkit-transform: scale(1.1);\n        -ms-transform: scale(1.1);\n            transform: scale(1.1); /* Efecto de zoom al pasar el ratón */\n}\n", ""]);

// exports
exports.locals = {
	"whatsappContainer": "WhatsAppIcon-whatsappContainer-3BIEG",
	"whatsappIcon": "WhatsAppIcon-whatsappIcon-2fEFd"
};