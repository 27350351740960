import React from 'react';
import Layout from '../../components/Layout';
import SecurityDeposit from './securityDepositOwner';


const title = 'Security deposit';

export default function action() {
  return {
    title,
    component: 
    <Layout>
      <SecurityDeposit />,
    </Layout>,
    status: 200,
  };
}