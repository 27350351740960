/* eslint-disable import/prefer-default-export */

import { SET_TOKEN } from '../constants';

export const setTokenVariable = ({ name, value }) => {
  return {
    type: SET_TOKEN,
    payload: {
      name,
      value
    },
  };
}
