import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './BookYourCar.css';
import cs from '../../commonStyle.css'
import cx from 'classnames';

//Image
import icon from '/public/SiteIcons/streeingWheel.svg';

class BookYourCar extends React.Component {
    render() {
        const { infoCollection } = this.props;

        return (
            <div className={s.bookGrid}>
                {infoCollection && <><img src={`/images/home/${infoCollection.image}`} className={cs.fullWidth} />
                    <div className={cx(s.paddingLeft, 'paddingLeftBookRTL')}>
                        <h2 className={cx(cs.commonTitleText, cs.fontWeightBold)}>
                            <FormattedMessage {...messages.bookYourCarHeading} />
                        </h2>
                        <div className={cx(cs.commonContentText, cs.paddingTop4, s.bookFlex)}>
                            <img src={icon} className={cx(s.iconCss, 'iconCssBookRTL')} />
                            <span><FormattedMessage {...messages.bookYourCarContent1} /></span>
                        </div>
                        <div className={cx(cs.commonContentText, cs.paddingTop4, s.bookFlex)}>
                            <img src={icon} className={cx(s.iconCss, 'iconCssBookRTL')} />
                            <span><FormattedMessage {...messages.bookYourCarContent2} /></span>
                        </div>
                        <div className={cx(cs.commonContentText, cs.paddingTop4, s.bookFlex)}>
                            <img src={icon} className={cx(s.iconCss, 'iconCssBookRTL')} />
                            <span><FormattedMessage {...messages.bookYourCarContent3} /></span>
                        </div>
                        {/* <div className={cx(cs.commonContentText, cs.paddingTop4, s.bookFlex)}>
                            <img src={icon} className={cx(s.iconCss, 'iconCssBookRTL')} />
                            <span><FormattedMessage {...messages.bookYourCarContent4} /></span>
                        </div>
                        <div className={cx(cs.commonContentText, cs.paddingTop4, s.bookFlex)}>
                            <img src={icon} className={cx(s.iconCss, 'iconCssBookRTL')} />
                            <span><FormattedMessage {...messages.bookYourCarContent5} /></span>
                        </div> */}
                        <a href={infoCollection.buttonLink} target="_blank" className={cx(cs.btnPrimary, cs.spaceTop4, cs.displayinlineBlock)}>
                            <FormattedMessage {...messages.bookYourCarButtonLabel} />
                        </a>
                    </div> </>}
            </div>
        );
    }
}

export default injectIntl(withStyles(s, cs)(BookYourCar));
