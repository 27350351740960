import React, { Component } from 'react';
import PropTypes from 'prop-types'; // Importar PropTypes
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';

import Accordion from './Accordion';
import s from './AccordionComponent.css';

import messages from '../../locale/messages';

class AccordionComponent extends Component {
    static propTypes = {
        intl: PropTypes.object.isRequired, // Definir el tipo de prop intl correctamente
    };

    render() {
        const { FormatMessage } = this.props.intl;

        return (
            <div className={s.container}>
                <h1 className={s.faqTitle}>Preguntas frecuentes</h1>
                <div className={s.accordionColumns}>
                    {/* Primera columna */}
                    <div className={s.accordionColumn}>
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq1} />}
                            content={<FormattedMessage {...messages.contentFaq1} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq2} />}
                            content={<FormattedMessage {...messages.contentFaq2} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq3} />}
                            content={<FormattedMessage {...messages.contentFaq3} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq4} />}
                            content={<FormattedMessage {...messages.contentFaq4} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq5} />}
                            content={<FormattedMessage {...messages.contentFaq5} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq6} />}
                            content={<FormattedMessage {...messages.contentFaq6} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq7} />}
                            content={<FormattedMessage {...messages.contentFaq7} />}
                        />
                    </div>
        
                    {/* Segunda columna */}
                    <div className={s.accordionColumn}>
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq8} />}
                            content={<FormattedMessage {...messages.contentFaq8} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq9} />}
                            content={<FormattedMessage {...messages.contentFaq9} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq10} />}
                            content={<FormattedMessage {...messages.contentFaq10} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq11} />}
                            content={<FormattedMessage {...messages.contentFaq11} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq12} />}
                            content={<FormattedMessage {...messages.contentFaq12} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq13} />}
                            content={<FormattedMessage {...messages.contentFaq13} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq14} />}
                            content={<FormattedMessage {...messages.contentFaq14} />}
                        />
                        <Accordion
                            title={<FormattedMessage {...messages.titelFaq15} />}
                            content={<FormattedMessage {...messages.contentFaq15} />}
                        />
                    </div>
                </div>
            </div>
        );
        
    }
}

export default injectIntl(withStyles(s)(AccordionComponent));
