import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './WhatsAppIcon.css';
import whastAppIcon from '../../../public/SiteIcons/whatsappLogo.svg';


class WhatsAppIcon extends Component {
  render() {
    return (
      <div className={s.whatsappContainer}>
        <a href="https://wa.me/+5493764671898" target="_blank" rel="noopener noreferrer">
          <img
            src={whastAppIcon} // Asegúrate de reemplazar esta ruta con la correcta
            alt="WhatsApp"
            className={s.whatsappIcon}
          />
        </a>
      </div>
    );
  }
}

export default withStyles(s)(WhatsAppIcon);
