import messages from '../../locale/messages';
const validate = values => {
   const errors = {}

   // Validación de nombre (obligatorio)
   if (!values.name || values.name.trim() === "") {
      errors.name = messages.required;
   }

   if (!values.lastname || values.lastname.trim() === "") {
      errors.lastname = messages.required;
   }

   // Validación de email (obligatorio)
   if (!values.email) {
      errors.email = messages.required;
   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
      errors.email = messages.emailInvalid;
   }

   // Validación de número de teléfono (obligatorio)
   if (!values.phoneNumber || values.phoneNumber.trim() === "") {
      errors.phoneNumber = messages.required;
   }

   // Validación de reCaptcha (obligatorio)
   if (!values.reCaptcha) {
       errors.reCaptcha = messages.required;
   }

   // Validación de año (obligatorio)
   if (!values.year) {
      errors.year = messages.required;
   }

   // Validación de origen (obligatorio)
   if (!values.origin) {
      errors.origin = messages.required;
   }

   // Validación de marca (obligatorio)
   if (!values.make || values.make.trim() === "") {
      errors.make = messages.required;
   }

   // Validación de modelo (obligatorio)
   if (!values.model || values.model.trim() === "") {
      errors.model = messages.required;
   }

   // validacion de tipo generico (obligatorio)
   if (!values.genericType) {
      errors.genericType = messages.required;
   }

   // Validación de uso del vehículo (obligatorio)
   if (!values.usage || values.usage.trim() === "") {
      errors.usage = messages.required;
   }


   // Validación de número de placa (obligatorio)
   if (!values.numberPlate || values.numberPlate.trim() === "") {
      errors.numberPlate = messages.required;
   }

   // Validación de VIN (obligatorio)
   if (!values.vin || values.vin.trim() === "") {
      errors.vin = messages.required;
   }

   // Validacion cobertura (obligatorio)
   if (!values.coverage) {
      errors.coverage = messages.required;
   }

   // Los siguientes campos son opcionales:
   // Accesorios, adicionales, valor del vehículo y mensaje de contacto

   return errors;
}

export default validate;