

export const paymentCalc = (fee, total) => {
    const toPay= (((total) * 0.20) + fee);
    return toPay.toFixed(2);

};

export const payCash = (fee,total) => {
    const payCash = ((total) - ((total) * 0.20));
    return payCash.toFixed(2);
};